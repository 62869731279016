<div class="container">
  <app-heading
    link="../training-overview"
    subheading="Erstellen Sie die nächste Braintraining-Challenge für {{clientService.client.first_name}} {{clientService.client.last_name}}">
    Trainingseditor
  </app-heading>

  <double-statistic
    *ngIf="this.totalTrainingDurSec >= 10"
    first_label="Anzahl Trainingseinheiten:"
    second_label="Trainingsdauer:"
    first_number="{{exerciseCollection.length}}"
    second_number = "{{this.totalTrainingDurMin.toString()+':'+this.totalTrainingDurSec.toString()}}">
  </double-statistic>
  <double-statistic
    *ngIf="this.totalTrainingDurSec == 0"
    first_label="Anzahl Trainingseinheiten:"
    second_label="Trainingsdauer:"
    first_number="{{exerciseCollection.length}}"
    second_number = "{{this.totalTrainingDurMin.toString()+':'+this.totalTrainingDurSec.toString()+'0'}}">
  </double-statistic>
  <double-statistic
    *ngIf="this.totalTrainingDurSec < 10 && this.totalTrainingDurSec != 0"
    first_label="Anzahl Trainingseinheiten:"
    second_label="Trainingsdauer:"
    first_number="{{exerciseCollection.length}}"
    second_number = "{{this.totalTrainingDurMin.toString()+':0'+this.totalTrainingDurSec.toString()}}">
  </double-statistic>

  <!--div class="py-auto" *ngIf="exercise.durSec != 0 && exercise.durSec >= 10">
    {{exercise.durMin}} <b>:</b> {{exercise.durSec}}
  </div>
  <div class="py-auto" *ngIf="exercise.durSec == 0">
    {{exercise.durMin}} <b>:</b> {{exercise.durSec}}0
  </div>
  <div class="py-auto" *ngIf="exercise.durSec < 10 && exercise.durSec != 0">
    {{exercise.durMin}} <b>:</b> 0{{exercise.durSec}}
  </div-->

  <div class="row mb-5">
    <div class="col-8 me-auto">
      <label class="mb-0">Notizen:</label>
      <input
        [(ngModel)] = "activeTraining.notes"
        class="form-control sul-text-field"
        type="text"
        name="notes"
        id="notes"
      />
    </div>

    <div class="col-auto">
      <label class="mb-0">Deadline:</label>
      <input
        [(ngModel)] = "this.deadline"
        placeholder="{{this.deadlineAsStr}}"
        onfocus="(this.type='date')"
        type="text"
        name="deadline"
        class="form-control sul-text-field"
      >
    </div>
  </div>

  <table class="table sul-table">
    <thead>
      <tr class="align-middle">
        <th scope="col"> </th>
        <th scope="col">Übungstyp</th>
        <th scope="col">Schwierigkeitsgrad</th>
        <th scope="col">Zeit (m:s)</th>
        <th scope="col"> </th>
      </tr>
    </thead>
    <tbody cdkDropList (cdkDropListDropped)="drop($event)" *ngIf="exerciseCollection?.length > 0;else noExercises">
      <tr *ngFor="let exercise of exerciseCollection; index as cnt"
          cdkDrag
          cdkDragLockAxis="y"
          (click)="onOpenParamsSetting(exercise, cnt, modal)"
          class="my-1 p-2 sul-box-table sul-tr align-middle">
          <td class="sul-xs me-2">
            <div cdkDragHandle class="py-auto">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="40" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 10 10">
                <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
              </svg>
            </div>
          </td>
          <td class="sul-md">
            <div *ngIf="exercise.exerciseType == null" class="py-auto">
              <em>Klicke hier um den Übungstyp festzulegen</em>
            </div>
            <div *ngIf="exercise.exerciseType != 'Übungstyp auswählen'" class="py-auto">
              {{exercise.exerciseType}}
            </div>
          </td>
          <td class="sul-md">
            <div *ngIf="exercise.difficulty == 0" class="py-auto">Beginner</div>
            <div *ngIf="exercise.difficulty == 1" class="py-auto">Einfach</div>
            <div *ngIf="exercise.difficulty == 2" class="py-auto">Medium</div>
            <div *ngIf="exercise.difficulty == 3" class="py-auto">Schwer</div>
            <div *ngIf="exercise.difficulty == 4" class="py-auto">Extrem</div>
          </td>
          <td class="sul-md">
            <div class="py-auto" *ngIf="exercise.durSec >= 10">
              {{exercise.durMin}} <b>:</b> {{exercise.durSec}}
            </div>
            <div class="py-auto" *ngIf="exercise.durSec == 0">
              {{exercise.durMin}} <b>:</b> {{exercise.durSec}}0
            </div>
            <div class="py-auto" *ngIf="exercise.durSec < 10 && exercise.durSec != 0">
              {{exercise.durMin}} <b>:</b> 0{{exercise.durSec}}
            </div>
          </td>
          <td class="sul-xs">
            <div class="text-end m-1 py-1">
              <a class="sul-btn my-1 me-2 p-2"  (click)="deleteExercise(exercise,exerciseCollection)">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </a>
            </div>
          </td>
      </tr>
      <tr (click)="addExercise()" class="my-1 p-2 sul-box-table sul-tr" height="56">
        <td colspan="4" class="align-middle">
          <div class="text-center" >
            <a  class="p-2 text-reset text-decoration-none ">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-plus" viewBox="0 0 18 18">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
              </svg>
              Übung hinzufügen
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="this.activeTraining.exercise_sequence.length > 0" class="row d-flex justify-content-between">
    <button (click)="cancel(false)" type="submit" value="Training speichern" class="col-auto sul-btn p-2 text-center">
      <span class="pe-3">Änderungen verwerfen</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
      </svg>
    </button>
    <button (click)="updateTraining(this.activeTraining.training_id)" type="submit" value="Training speichern" class="col-auto sul-btn p-2 text-center">
      <span class="pe-3"> Änderungen speichern</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
      </svg>
    </button>
  </div>

  <div *ngIf="this.activeTraining.exercise_sequence.length == 0" class="row d-flex justify-content-between">
    <button (click)="cancel(true)" type="submit" value="Training speichern" class="col-auto sul-btn p-2 text-center">
      <span class="pe-3">Abbrechen</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
      </svg>
    </button>
    <button (click)="saveTraining()" type="submit" value="Training speichern" class="col-auto sul-btn p-2 text-center">
      <span class="pe-3"> Training speichern</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-save" viewBox="0 0 16 16">
        <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
      </svg>
    </button>
  </div>
</div>

<ng-template #noExercises>
  <tbody class="my-1 p-2 sul-box-table sul-tr align-middle">
    <tr (click)="addExercise()" class="my-1 p-2 sul-box-table sul-tr" height="56">
      <td colspan="4" class="align-middle">
        <div class="text-center" >
          <a  class="p-2 text-reset text-decoration-none ">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-plus" viewBox="0 0 18 18">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
            Übung hinzufügen
          </a>
        </div>
      </td>
    </tr>
  </tbody>
</ng-template>

<ng-template #modal let-modal>
    <div>
      <div class="modal-header justify-content-center">
       <h4 class="modal-title">Übungsparameter</h4>
      </div>
      <div class="modal-body px-5">
        <form #exerciseForm="ngForm" (keydown.enter)="onSubmitParams(exerciseForm, this.currExcsID)" class="mt-3 mb-0 mx-1">

          <div class="my-3">
            <select  [(ngModel)]="this.currExcsType" class="form-select sul-btn" aria-label="Default select example" name="exerciseType" required>
              <option *ngIf="this.currExcsType == null" value="null">Übungstyp auswählen</option>
              <option value= 'Reaktion'>Reaktion</option>
              <option value= 'Gedächtnis'>Gedächtnis</option>
              <option value= 'Räumliches Denken'>Räumliches Denken</option>
              <option value= 'Hand-Auge Koordination'>Hand-Auge Koordination</option>
              <option value= 'Ausweichtraining'>Ausweichtraining</option>
            </select>

          </div>

          <div class="row justify-content-around">
            <div class="row">
              <div class="col-3" *ngIf="this.currExcsType != null">
                <div class="row">
                  <div class="col">
                    <label for="durMin" class="form-label mb-0">Min</label>
                    <input (keydown.enter)="$event.preventDefault()" type="number" placeholder="0" name ="durMin" id="durMin" class="form-control sul-text-field"
                           [(ngModel)] = "this.currExcsDurMin"
                           maxlength="2"
                           min="0"
                           max="59"/>
                  </div>
                  <div class="col">
                    <label for="durSec" class="form-label mb-0">Sek</label>
                    <input (keydown.enter)="$event.preventDefault()" type="number" placeholder="0" name ="durSec" id="durSec" class="form-control sul-text-field"
                           [(ngModel)] = "this.currExcsDurSec"
                           maxlength="2"
                           min="0"
                           max="59"/>
                  </div>
                </div>
              </div>
              <div class="dropdown col" *ngIf="this.currExcsType != null">
                <label for="difficulty" class="form-label mb-0">Schwierigkeit:</label>
                <button
                  [(ngModel)] = "this.currExcsDiff"
                  class="sul-btn dropdown-toggle my-2 ms-3 p-2 "
                  type="button"
                  name ="difficulty"
                  id="difficulty"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">{{this.currExcsDiffAsStr}}
                </button>
                <ul class="dropdown-menu sul-dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><button *ngIf="this.currExcsType == 'Gedächtnis'" class="dropdown-item sul-dropdown" type="button" value="Beginner" (click)="onReadOnChangeExDiff(0)">Beginner</button></li>
                  <li><button class="dropdown-item sul-dropdown" type="button" value="Einfach" (click)="onReadOnChangeExDiff(1)">Einfach</button></li>
                  <li><button class="dropdown-item sul-dropdown" type="button" value="Medium" (click)="onReadOnChangeExDiff(2)">Medium</button></li>
                  <li><button class="dropdown-item sul-dropdown" type="button" value="Schwer" (click)="onReadOnChangeExDiff(3)">Schwer</button></li>
                  <li><button *ngIf="this.currExcsType != 'Reaktion'" class="dropdown-item sul-dropdown" type="button" value="Extrem" (click)="onReadOnChangeExDiff(4)">Extrem</button></li>
                </ul>
              </div>
            </div>

            <div class="mb-3 row" *ngIf="this.currExcsType != null">
              <div class="form-check col my-auto mx-auto" *ngIf="this.currExcsType == 'Reaktion'" >
                <input
                  [(ngModel)] = "exerciseCollection[this.currExcsID].colored_mode"
                  class="form-check-input "
                  type="checkbox"
                  name="colored_mode"
                  id="colored_mode"
                />
                <label class="form-check-label" for="colored_mode"> mehrfarbiger Modus</label>
              </div>
            </div>
          </div>

          <div class="modal-footer pb-0 d-flex justify-content-between">
            <button class="btn sul-btn" (click)="modal.close('Abort click'); cancelParamsSetting(this.currExcsID)">Abbrechen</button>
            <button type="submit" class="btn sul-btn" (click)="modal.close('Save click'); onSubmitParams(exerciseForm, this.currExcsID)">Speichern</button>
          </div>

        </form>

      </div>


    </div>
</ng-template>

<ng-template #matrixParams let-modal>

</ng-template>
