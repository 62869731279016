import { NumberSymbol } from '@angular/common';
import { Observable } from 'rxjs';
import { Address } from './Address';
import { Gender } from './Gender';
import { Training } from './Training';
import { HealthData } from './HealthData';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;



class PersonalHighscores {
    Matrix: number;
    ThreeDMemory: number;
    Shadow: number;
    Ball: number;
    Dodge: number;

}

export class Client {

  id: string; // make it readonly (immutable)
  username?: string;
  first_name: string;
  last_name: string;
  gender: Gender;
  birthday: Timestamp;
  e_mail: string;
  phone: string;
  address: Address ;
  health_data?: HealthData;
  personal_highscores: PersonalHighscores;
  trainings_counter: { count_staged_trainings: number,
                      count_saved_trainings: number};
  staged_trainings?: Training[];
  saved_trainings?: Training[];


  constructor(){
  }

}


