import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {EditorExercise, TrainingseditorComponent} from '../../pages/trainingseditor/trainingseditor.component';
import {FlashMessagesService} from 'flash-messages-angular';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


@Component({
  selector: 'app-new-exercise-dialog',
  templateUrl: './new-exercise-dialog.component.html',
  styleUrls: ['./new-exercise-dialog.component.scss']
})
export class NewExerciseDialogComponent implements OnInit {

  newTraining: EditorExercise;
  closeDialog: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private flashMessage: FlashMessagesService,
    @Inject(MAT_DIALOG_DATA) public data: EditorExercise,
  ) { }

  ngOnInit(): void {
  }


  onSubmit(value: EditorExercise): void {
    // TODO: deliver EditorExercise to trainingseditor component
    this.flashMessage.show('Die Übung wurde übernommen', {cssClass: 'alert-success', timeout: 4000});
    //this.closeDialog.emit(true);
  }

}
