<div class="row">
    <div class="col-md-7 mx-auto">
        <div class="card">
            <div class="sul-box-raised-1 align-items-center d-flex flex-column">
                <div class="my-3">
                  <img src="./assets/img/brainjo.png" height="150">
                </div>
                <div class="mb-3">
                  <h4 class="text-center">
                    Trainer Login
                  </h4>
                </div>
                <div>
                  <form (submit)="onSubmit()" class="mb-3 align-items-center d-flex flex-column form-horizontal">
                    <div class="form-group mb-2">
                        <label for="email" class="m-2 mx-4">Email</label>
                        <input type="email" name="email" [(ngModel)]="email" class="sul-text-field px-2" required>
                    </div>
                    <div class="form-group mb-2">
                        <label for="password" class="m-2">Password</label>
                        <input type="password" name="password" [(ngModel)]="password" class="sul-text-field px-2" required>
                    </div>
                    <div>
                      <button type="submit" class="sul-btn px-3 py-2">Login</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
</div>

