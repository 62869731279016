import {Injectable} from '@angular/core';
import {Training} from '../models/Training';
import {EditorExercise, ExerciseType} from '../pages/trainingseditor/trainingseditor.component';


@Injectable({
  providedIn: 'root'
})

export class TrainingsConverterService {
 dbTraining: Training;

  constructor() {}

  getEditorExcs(): EditorExercise[] {

    return null;
  }

  convertToTraining(convertingType: EditorExercise[]): { training: Training; sequenceList: string[] } {

   const training: Training = new Training();
   let matrixCounter = 0;
   let memoryCounter = 0;
   let shadowCounter = 0;
   let ballCounter = 0;
   let dodgeCounter = 0;
   const sequenceList: string[] = [];

   for (const i of convertingType) {
    switch (i.exerciseType) {
      case ExerciseType.Matrix: {
        training.exercises.matrixExcs.push(
          {input_params : {duration: i.duration,
                            difficulty: i.difficulty,
                            colored_mode: i.colored_mode}});
        training.exercise_sequence.push(`matrix_${matrixCounter}`);
        sequenceList.push(`matrix_${matrixCounter}`);
        matrixCounter += 1;
        break;
      }
      case ExerciseType.Memory: {
        training.exercises.memory3DExcs.push(
          {input_params : {duration: i.duration,
            difficulty: i.difficulty}});
        training.exercise_sequence.push(`memory_${memoryCounter}`);
        sequenceList.push(`memory_${memoryCounter}`);
        memoryCounter += 1;
        break;
      }
      case ExerciseType.Shadow: {
        training.exercises.shadowExcs.push(
          {input_params : {duration: i.duration,
              difficulty: i.difficulty}});
        training.exercise_sequence.push(`shadow_${shadowCounter}`);
        sequenceList.push(`shadow_${shadowCounter}`);
        shadowCounter += 1;
        break;
      }
      case ExerciseType.Ball: {
        training.exercises.ballExcs.push(
          {input_params : {duration: i.duration,
              difficulty: i.difficulty}});
        training.exercise_sequence.push(`ball_${ballCounter}`);
        sequenceList.push(`ball_${ballCounter}`);
        ballCounter += 1;
        break;
      }
      case ExerciseType.Dodge: {
        training.exercises.dodgeExcs.push(
          {input_params : {duration: i.duration,
              difficulty: i.difficulty}});
        training.exercise_sequence.push(`dodge_${dodgeCounter}`);
        sequenceList.push(`dodge_${dodgeCounter}`);
        dodgeCounter += 1;
        break;
      }
    }
  }
   console.log(training);
   console.log(sequenceList);
   return {training, sequenceList};
  }

  convertToEditorExcs(convertingType: Training): EditorExercise[] {

    // clean stashed exercises
    const editorExercises = [];
    // define exercise sequence names using regex expressions
    const regExMatrix = /matrix_\d/;
    const regExMemory = /memory_\d/;
    const regExShadow = /shadow_\d/;
    const regExBall = /ball_\d/;
    const regExDodge = /dodge_\d/;

    for (const i of convertingType.exercise_sequence){
      if (regExMatrix.test(i)) {
          const ex = convertingType.exercises.matrixExcs.shift();
          editorExercises.push(
              new EditorExercise(ExerciseType.Matrix, ex.input_params.difficulty, ex.input_params.duration,
                ex.input_params.colored_mode));
        }
      else if (regExMemory.test(i)) {
          const ex = convertingType.exercises.memory3DExcs.shift();
          editorExercises.push(
            new EditorExercise(ExerciseType.Memory, ex.input_params.difficulty, ex.input_params.duration));
        }
      else if (regExShadow.test(i)) {
          const ex = convertingType.exercises.shadowExcs.shift();
          editorExercises.push(
            new EditorExercise(ExerciseType.Shadow, ex.input_params.difficulty, ex.input_params.duration));
        }
      else if (regExBall.test(i)) {
        const ex = convertingType.exercises.ballExcs.shift();
        editorExercises.push(
          new EditorExercise(ExerciseType.Ball, ex.input_params.difficulty, ex.input_params.duration));
        }
      else if (regExDodge.test(i)) {
        const ex = convertingType.exercises.dodgeExcs.shift();
        editorExercises.push(
          new EditorExercise(ExerciseType.Dodge, ex.input_params.difficulty, ex.input_params.duration));
      }
      }

    return editorExercises;
  }

}
