import { Component, OnInit } from '@angular/core';
import {switchMap, map, takeUntil} from 'rxjs/operators';
import {TrainerService} from '../../services/trainer.service';
import {AuthService} from '../../services/auth.service';
import {ClientService} from '../../services/client.service';
import {Subject} from 'rxjs';
import {Client} from '../../models/Client';
import {Trainer} from '../../models/Trainer';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';

@Component({
  selector: 'app-dashboard',
  templateUrl: './trainer-dashboard.component.html',
  styleUrls: ['./trainer-dashboard.component.scss']
})
export class TrainerDashboardComponent implements OnInit {
  clientsCollection: AngularFirestoreCollection<Client>;

  quote: string;
  quotes: any;
  countStagedtrainings = 0;
  countSavedtrainings = 0;

  trainer = new Trainer();
  // users: Client[];

  unsubscribe: Subject<void> = new Subject();

  constructor(private clientService: ClientService,
              private trainerService: TrainerService,
              private authService: AuthService,
              private afs: AngularFirestore) { }

  ngOnInit(): void {

    this.authService.getAuth().pipe(takeUntil(this.unsubscribe)).subscribe(auth => {
      if (auth) {
        this.trainerService.getTrainer(auth.uid).pipe(takeUntil(this.unsubscribe)).subscribe(trainer => {
          this.trainer = trainer;

          // query and show randomized quotes
          // TODO: update motivation document from string array to map array {author: 'name', quote: 'His/her pretty quote'}
          this.afs.collection('app_features').doc('motivation')
              .get().toPromise().then((doc => {
              this.quotes = doc.data() as string[];
              this.quote = this.quotes.quotes[Math.floor(Math.random() * this.quotes.quotes.length + 1)];
          }));

          // query and count trainings
          for (const item of this.trainer.user_list) {
            // query and count saved trainings
            this.afs.collection('users').doc(item)
              .collection('trainings', ref => ref.where('is_completed', '==', true)).get().toPromise()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  this.countSavedtrainings += 1;
              });
          });
            // query and count staged trainings
            this.afs.collection('users').doc(item)
              .collection('trainings', ref => ref.where('is_completed', '==', false)).get().toPromise()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  this.countStagedtrainings += 1;
                });
              });
          }
        });
      }
  });

}

}

