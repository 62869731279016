import { Address } from './Address';
import { Gender } from './Gender';


import DateTime from 'additional_modules/DateTime';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;


export class Trainer {
    id: string; // make it readonly if possible
    first_name: string;
    last_name: string;
    gender: Gender;
    birthday: Timestamp;
    organisation: string;
    sector: string;
    e_mail: string;
    phone: string;
    address: Address;
    user_list: string[];

   constructor(){}

}
