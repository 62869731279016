<nav class="m-3">
  <div class="d-flex justify-content-between" *ngIf="!isLoggedIn">
    <div *ngIf="showRegister" class="sul-btn mx-2">
      <a routerLink="/register" class="nav-link">Als Trainer registrieren</a>
    </div>
    <div class="sul-btn">
      <a routerLink="/login" class="nav-link">Anmelden</a>
    </div>
  </div>
  <div *ngIf="isLoggedIn" class="d-flex justify-content-center align-items-center flex-wrap">
    <div class="w-25 m-1 text-break">
      <a routerLink="/settings" class="nav-link text-reset text-decoration-none text-start">{{ loggedInUser }}</a>
    </div>
    <div class="align-self-center ms-auto me-auto">
      <a href="#" class="nav-link"><img src="./assets/img/brainjo.png" width="100"></a>
    </div>
    <div class="w-25 m-1 ps-auto">
      <a href="#" (click)="onLogoutClick()" class="nav-link text-reset text-decoration-none text-end">Logout</a>
    </div>
  </div>
</nav>
