<div class="d-flex justify-content-start align-items-center flex-wrap">
  <a [routerLink]="link" class="text-reset text-decoration-none">
    <div class="sul-btn p-2 px-3">

      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left"
           viewBox="0 0 16 16">
        <path fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
      </svg>
    </div>
  </a>
  <h2 class="mx-auto text-center"><ng-content></ng-content></h2>
  <div>
    <!--fake element that is not visible but helps to center the the text.
      Needs to be left there.
      If size of back button is adjusted, this also needs adjustments!-->
    <div display="hidden">
      <svg width="48" height="40" viewBox="0 0 48 40"></svg>
    </div>
  </div>
</div>
<div class="my-3 d-flex justify-content-center align-items-center flex-wrap">
  <h5>{{ subheading }}</h5>
</div>

