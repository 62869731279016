import {Component, Inject, Injectable, OnDestroy, OnInit} from '@angular/core';


import { ClientService } from '../../services/client.service';
import { Client } from '../../models/Client';
import { TrainerService } from 'src/app/services/trainer.service';
import { Trainer } from 'src/app/models/Trainer';
import { AuthService } from 'src/app/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';




@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})

@Injectable({
  providedIn: 'any'
})

export class ClientsComponent implements OnInit, OnDestroy {
  trainer = new Trainer();
  users: Client[];

  usersLoaded: Promise<boolean>;

  unsubscribe: Subject<void> = new Subject();

  constructor(private clientService: ClientService,
              private trainerService: TrainerService,
              private authService: AuthService) { }

  ngOnInit(): void {

    this.authService.getAuth().pipe(takeUntil(this.unsubscribe)).subscribe(auth => {
      if (auth) {
        this.trainerService.getTrainer(auth.uid).pipe(takeUntil(this.unsubscribe)).subscribe(trainer => {
          // this.users = [];
          this.trainer = trainer;
          this.users = this.clientService.getClients(this.trainer.user_list);
          this.usersLoaded = Promise.resolve(true); // Setting the Promise as resolved after we have the needed data

        });
        }

      // console.log('Add  new trainer to database:');
      // this.trainerService.newTrainer(this.trainer);

    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.clientService.unsubscribe.next();
    this.clientService.unsubscribe.complete();
  }
}

