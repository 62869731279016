export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDaP9Tqtk7sEdgdCpQSf9ptNkAGHdadX_g",
    authDomain: "brainjoprod.firebaseapp.com",
    projectId: "brainjoprod",
    storageBucket: "brainjoprod.appspot.com",
    messagingSenderId: "482014090520",
    appId: "1:482014090520:web:daf35cc568efa4f54bfa6d"
  }
};
