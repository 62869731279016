import DateTime from 'additional_modules/DateTime';
import { Exercises } from './exercise_models/Exercises';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import {MatrixEx} from './exercise_models/MatrixEx';
import {SortEx} from './exercise_models/SortEx';
import {ShadowEx} from './exercise_models/ShadowEx';
import {BallEx} from './exercise_models/BallEx';
import {Memory3DEx} from './exercise_models/Memory3DEx';
import {DodgeEx} from './exercise_models/DodgeEx';

export class AverageScores {
  matrix: number;
  memory3D: number;
  shadow: number;
  ball: number;
  dodge: number;
}

export class Training {
    training_id ?: string;
    created_at: Date;
    deadline: Date;
    completed_at ?: Date;
    notes ?: string;
    trainer: string;
    exercise_sequence: string[];
    exercises?: Exercises;

    // only used in saved trainings
    feedback ?: string;
    is_free_training ?: boolean;
    is_completed ?: boolean;
    is_running: boolean;

    average_scores?: AverageScores;

    matrixScores?: number[];
    sortSCores?: number[];
    shadowScores?: number[];
    ballScores?: number[];
    memory3DScores?: number[];
    dodgeScores?: number[];


    constructor(){
        this.exercises = new Exercises();
        this.exercise_sequence = [];

        // this.matrixScores = [];
        // this.sortSCores = [];
        // this.shadowScores = [];
        // this.ballScores = [];
        // this.memory3DScores = [];
        // this.dodgeScores = [];
    }

}
