import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';

import { TrainerDashboardComponent } from './pages/trainer-dashboard/trainer-dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { AddClientComponent } from './pages/add-client/add-client.component';
import { EditClientComponent } from './pages/edit-client/edit-client.component';
import { ClientDetailsComponent } from './pages/client-details/client-details.component';
import { ClientDashboardComponent} from './pages/client-dashboard/client-dashboard.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { RegisterGuard } from './guards/register.guard';
import {TrainingseditorComponent} from './pages/trainingseditor/trainingseditor.component';
import {TrainingOverviewComponent} from './pages/training-overview/training-overview.component';
import {TrainingInsightsComponent} from './pages/training-insights/training-insights.component';
import {Training} from './models/Training';

export const routes: Routes = [
  {path: '', component: TrainerDashboardComponent, canActivate: [AuthGuard]},
  {path: 'clients', component: ClientsComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent, canActivate: [RegisterGuard]},
  {path: 'clients/client/add', component: AddClientComponent, canActivate: [AuthGuard]},
  {path: 'client/edit/:id', component: EditClientComponent, canActivate: [AuthGuard]},
  {path: 'clients/clientmeta/:id', component: ClientDetailsComponent, canActivate: [AuthGuard]},
  {path: 'client/:id/client-dashboard', component: ClientDashboardComponent, canActivate: [AuthGuard]}, // TODO: set [AuthGuard] to [OwnUserGuard]
  {path: 'client/:id/trainings-editor', component: TrainingseditorComponent, data: {receivedTraining: Training},  canActivate: [AuthGuard]}, // TODO: set [AuthGuard] to [OwnUserGuard]
  {path: 'client/:id/training-overview', component: TrainingOverviewComponent, canActivate: [AuthGuard]}, // TODO: set [AuthGuard] to [OwnUserGuard]
  {path: 'client/:id/training-insights/:tid', component: TrainingInsightsComponent, canActivate: [AuthGuard]}, // TODO: set [AuthGuard] to [OwnUserGuard]

  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes)
  ],
  providers: [AuthGuard, RegisterGuard]
})
export class AppRoutingModule {  }

