<div class="container">
  <app-heading subheading="Behalte stets den Überblick über deine Mitglieder">Meine Clients</app-heading>

  <div *ngIf="usersLoaded | async">
    <double-statistic
      first_label="Mitglieder gesamt:"
      second_label="Derzeit aktiv:"
      first_number="{{ trainer.user_list.length }}"
      second_number="12 #">
    </double-statistic>

    <table *ngIf="users?.length > 0;else noClients" class="table sul-table">
      <tbody>
      <tr *ngFor="let client of users" data-href="clientdashboard/{{ client.id }}"
          class="my-1 p-2 sul-box-table clickable-row sul-tr align-middle"
          routerLink="/client/{{ client.id }}/client-dashboard">
            <td class="table-names me-5">{{ client.first_name }} {{ client.last_name }}</td>
            <td class="d-none d-md-table-cell text-center text-break">{{ client.e_mail }}</td>
            <td class="d-none d-lg-table-cell text-center text-break">{{ client.phone}}</td>
            <td class="py-auto">
              <div class="bg-warning text-black text-center py-1 px-2 m-auto" *ngIf="client.trainings_counter.count_staged_trainings == 0">
                Kein Training offen
              </div>
              <div class="bg-primary text-white text-center py-1 px-2 m-auto" *ngIf="client.trainings_counter.count_staged_trainings == 1">
                <span class="fw-bold">{{ client.trainings_counter.count_staged_trainings }}</span> Training offen
              </div>
              <div class="bg-primary text-white text-center py-1 px-2 m-auto" *ngIf="client.trainings_counter.count_staged_trainings > 1">
                <span class="fw-bold">{{ client.trainings_counter.count_staged_trainings }}</span> Trainings offen
              </div>
            </td>
            <td>
              <span class="fw-bold">{{ client.trainings_counter.count_saved_trainings }}</span> Trainings abgeschlossen
            </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="d-flex justify-content-center">
    <input type="submit" value="Client hinzufügen" class="sul-btn p-2 text-center" routerLink="client/add">
  </div>

<ng-template #noClients>
    <hr>
    <h5>There are no clients in the system</h5>
</ng-template>

