<div class="container">
  <app-heading link="/">Einstellungen</app-heading>

  <div class="card border-0 shadow my-3">
      <div class="card-header">
          Edit Settings
      </div>
      <div class="card-body">
          <form (submit)="onSubmit()">
            <div class="dropdown">
              <button class="sul-btn dropdown-toggle my-2 p-2 " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                Dropdown button
              </button>
              <div class="dropdown-menu sul-dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <a class="dropdown-item sul-dropdown" href="#">Action</a>
                <a class="dropdown-item sul-dropdown" href="#">Another action</a>
                <a class="dropdown-item sul-dropdown" href="#">Something else here</a>
              </div>
            </div>

            <div class="my-3">
              <label for="formFile" class="form-label">Default file input example</label>
              <input class="form-control shadow border-secondary" type="file" id="formFile">
            </div>

            <div class="my-3">
              <label for="exampleDataList" class="form-label">Datalist example</label>
              <input class="form-control sul-text-field" list="datalistOptions" id="exampleDataList" placeholder="Type to search...">
              <datalist id="datalistOptions">
                <option value="San Francisco">
                <option value="New York">
                <option value="Seattle">
                <option value="Los Angeles">
                <option value="Chicago">
              </datalist>
            </div>

            <div class="my-3">
              <select class="form-select sul-btn" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>


            <div>
              <div class="form-check">
                <input
                  class="form-check-input shadow"
                  type="checkbox"
                  name="allowRegistration"
                  id="allowRegistration"
                  [checked]="settings.allowRegistration"
                  (change)="settings.allowRegistration = !settings.allowRegistration"
                />
                <label
                  class="form-check-label"
                  for="allowRegistration"
                >
                  Allow Registration
                </label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="disableBalanceOnAdd"
                  id="disableBalanceOnAdd"
                  [checked]="settings.disableBalanceOnAdd"
                  (change)="settings.disableBalanceOnAdd = !settings.disableBalanceOnAdd"
                />
                <label
                  class="form-check-label"
                  for="disableBalanceOnAdd"
                >
                  Disable Balance on Add
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="disableBalanceOnEdit"
                  id="disableBalanceOnEdit"
                  [checked]="settings.disableBalanceOnEdit"
                  (change)="settings.disableBalanceOnEdit = !settings.disableBalanceOnEdit"
                />
                <label
                  class="form-check-label"
                  for="disableBalanceOnEdit"
                >
                  Disable Balance on Edit
                </label>
              </div>
            </div>

            <div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                <label class="form-check-label" for="flexRadioDefault1">
                  Default radio
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
                <label class="form-check-label" for="flexRadioDefault2">
                  Default checked radio
                </label>
              </div>
            </div>
            <input type="submit" value="Submit" class="sul-btn btn btn-block my-3">
          </form>
      </div>
  </div>
</div>
