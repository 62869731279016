<div mat-dialog-content class="pad">

<!--div class="row">
  <div class="mx-auto"-->
    <div class="card">
      <div class="sul-box-raised-1 with-hover">

        <h4 class="text-center mat-dialog-title">
          Trainingsparameter setzen
        </h4>

        <form #exerciseForm="ngForm" (ngSubmit)="onSubmit(exerciseForm)">
          <div class="form-group pad-input">
            <div class="row"><label for="excType">Übungstyp:</label></div>
            <input type="radio" class="sul-radio" name="excType" value="Matrix" [ngModel]="data.exerciseType"> Matrix
            <input type="radio" class="sul-radio" name="excType" value="Memory" [ngModel]="data.exerciseType"> Memory
            <input type="radio" class="sul-radio" name="excType" value="Shadow" [ngModel]="data.exerciseType"> Shadow
            <input type="radio" class="sul-radio" name="excType" value="Ball" [ngModel]="data.exerciseType"> Ball
          </div>
          <div class="form-group pad-input">
            <div class="row"><label for="excDiff">Level:</label></div>
            <input type="radio" class="sul-radio" name="excDiff" value="Easy" [ngModel]="data.difficulty"> Easy
            <input type="radio" class="sul-radio" name="excDiff" value="Medium" [ngModel]="data.difficulty"> Medium
            <input type="radio" class="sul-radio" name="excDiff" value="Hard" [ngModel]="data.difficulty"> Hard
          </div>

          <input type="submit" value="Parameter übernehmen" class="sul-btn">
        </form>
      <!--/div>
      </div-->

    </div>
</div>
</div>
