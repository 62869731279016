import {EventEmitter, Injectable} from '@angular/core';

import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

import {Client} from '../models/Client';
import {AverageScores, Training} from '../models/Training';

import {MatrixEx} from '../models/exercise_models/MatrixEx';
import {Exercises} from '../models/exercise_models/Exercises';
import {BallEx} from '../models/exercise_models/BallEx';
import {Memory3DEx} from '../models/exercise_models/Memory3DEx';
import {ShadowEx} from '../models/exercise_models/ShadowEx';
import {DodgeEx} from '../models/exercise_models/DodgeEx';


@Injectable({
  providedIn: 'root'
})

export class ClientService {

  constructor(private afs: AngularFirestore) {
    this.clientsCollection = this.afs.collection('users');

  }

  clientsCollection: AngularFirestoreCollection<Client>;
  clientDoc: AngularFirestoreDocument<Client>;
  clients: Observable<Client[]>;
  clientsNEW: Client[];
  client: Client;

  unsubscribe: Subject<void> = new Subject();


  private static async writeExercises(
    trainingDoc: AngularFirestoreDocument, exercises: Exercises, exSequ: string[], isUpdate: boolean): Promise<boolean> {
    // set exercise counter for new exercises
    let matrixCount = 0;
    let memoryCount = 0;
    let shadowCount = 0;
    let ballCount = 0;
    let dodgeCount = 0;
    // set exercise counter for existing exercises
    let matrixSize;
    let memorySize;
    let shadowSize;
    let ballSize;
    let dodgeSize;
    // set CRUD progress flags
    let matrixReady = false;
    let memoryReady = false;
    let shadowReady = false;
    let ballReady = false;
    let dodgeReady = false;

    for (const i of exercises.matrixExcs) {
      trainingDoc.collection('matrix').doc(`matrix_${matrixCount}`).set(
        {
          input_params:
            {
              difficulty: i.input_params.difficulty,
              duration: i.input_params.duration,
              colored_mode: i.input_params.colored_mode
            }
        }).then(t => {
        matrixReady = true;
      });
      matrixCount += 1;
    }
    for (const i of exercises.memory3DExcs) {
      trainingDoc.collection('memory').doc(`memory_${memoryCount}`).set(
        {
          input_params:
            {
              difficulty: i.input_params.difficulty,
              duration: i.input_params.duration
            }
        }).then(t => {
        memoryReady = true;
      });
      memoryCount += 1;
    }
    for (const i of exercises.shadowExcs) {
      trainingDoc.collection('shadow').doc(`shadow_${shadowCount}`).set(
        {
          input_params:
            {
              difficulty: i.input_params.difficulty,
              duration: i.input_params.duration
            }
        }).then(t => {
        shadowReady = true;
      });
      shadowCount += 1;
    }
    for (const i of exercises.ballExcs) {
      trainingDoc.collection('ball').doc(`ball_${ballCount}`).set(
        {
          input_params:
            {
              difficulty: i.input_params.difficulty,
              duration: i.input_params.duration
            }
        }).then(t => {
        ballReady = true;
      });
      ballCount += 1;
    }
    for (const i of exercises.dodgeExcs) {
      trainingDoc.collection('dodge').doc(`dodge_${dodgeCount}`).set(
        {
          input_params:
            {
              difficulty: i.input_params.difficulty,
              duration: i.input_params.duration
            }
        }).then(t => {
        dodgeReady = true;
      });
      dodgeCount += 1;
    }

    // if training document shall be updated run the code below
    if (isUpdate) {
      let matrixCount: boolean;
      let memoryCount: boolean;
      let shadowCount: boolean;
      let ballCount: boolean;
      let dodgeCount: boolean;
      // count exercise-documents before updating the dataset
      trainingDoc.collection('matrix').get().toPromise().then(snap => {
        matrixSize = snap.size;
        matrixCount = true;
      });
      trainingDoc.collection('memory').get().toPromise().then(snap => {
        memorySize = snap.size;
        memoryCount = true;
      });
      trainingDoc.collection('shadow').get().toPromise().then(snap => {
        shadowSize = snap.size;
        shadowCount = true;
      });
      trainingDoc.collection('ball').get().toPromise().then(snap => {
        ballSize = snap.size;
        ballCount = true;
      });
      trainingDoc.collection('dodge').get().toPromise().then(snap => {
        dodgeSize = snap.size;
        dodgeCount = true;
      });

      await new Promise(f => setTimeout(f, 1000));
      // check for unused trainings
      if (matrixCount && memoryCount && shadowCount && ballCount && dodgeCount) {
        this.checkForUnusedTrainings(trainingDoc, [matrixSize, memorySize, shadowSize, ballSize, dodgeSize], exSequ);
      }

    }

    if (matrixReady && memoryReady && shadowReady && ballReady && dodgeReady) {
      return true;
    }
  }

  private static checkForUnusedTrainings(doc, sizeArr: number[], exSequ: string[]): void {
    // define exercise sequence names using regex expressions
    const regExMatrix = /matrix_\d/;
    const regExMemory = /memory_\d/;
    const regExShadow = /shadow_\d/;
    const regExBall = /ball_\d/;
    const regExDodge = /dodge_\d/;

    // initialize diffs as counter variable
    let diffMatrix = sizeArr[0];
    let diffMemory = sizeArr[1];
    let diffShadow = sizeArr[2];
    let diffBall = sizeArr[3];
    let diffDodge = sizeArr[4];

    // iterate over the exercise-sequence and calculate the diffs between old and new training
    for (const i of exSequ) {
      if (regExMatrix.test(i)) {
        diffMatrix -= 1;
      }
      if (regExMemory.test(i)) {
        diffMemory -= 1;
      }
      if (regExShadow.test(i)) {
        diffShadow -= 1;
      }
      if (regExBall.test(i)) {
        diffBall -= 1;
      }
      if (regExDodge.test(i)) {
        diffDodge -= 1;
      }
    }

    // delete the outdated exercise documents
    if (diffMatrix > 0) {
      for (let i = 1; i <= diffMatrix; i++) {
        doc.collection(`matrix`).doc(`matrix_${sizeArr[0] - i}`).delete();
      }
    }
    if (diffMemory > 0) {
      for (let i = 1; i <= diffMemory; i++) {
        doc.collection(`memory`).doc(`memory_${sizeArr[1] - i}`).delete();
      }
    }
    if (diffShadow > 0) {
      for (let i = 1; i <= diffShadow; i++) {
        doc.collection(`shadow`).doc(`shadow_${sizeArr[2] - i}`).delete();
      }
    }
    if (diffBall > 0) {
      for (let i = 1; i <= diffBall; i++) {
        doc.collection(`ball`).doc(`ball_${sizeArr[3] - i}`).delete();
      }
    }
    if (diffDodge > 0) {
      for (let i = 1; i <= diffDodge; i++) {
        doc.collection(`dodge`).doc(`dodge_${sizeArr[4] - i}`).delete();
      }
    }
  }


  getClients(userIds: string[]): Client[] {

    this.clientsNEW = [];
    let i = 0;


    for (const id of userIds) {
      // set document reference for pipe
      const clientDoc = this.afs.doc<Client>(`users/${id}`);

      // get client meta dataset
      this.getClientMetaData(clientDoc).pipe(takeUntil(this.unsubscribe)).subscribe(client => {
        if (client == null) {
          console.log('client is NULL');
        } else {
          i += 1;

          // explore clientsNEW
          const clientIdx = this.clientsNEW.findIndex(c => c.id === client.id);

          if (clientIdx !== -1) {
            this.clientsNEW[clientIdx] = client;
          } else {
            this.clientsNEW.push(client);
          }
        }
      });
    }
    return this.clientsNEW;
  }


  getClientMetaData(clientDoc: AngularFirestoreDocument<Client>): Observable<Client> {

    return clientDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        return action.payload.data() as Client;
      }
    }));
  }


  getClientDataset(clientDoc: AngularFirestoreDocument<Client>): Observable<Client> {

    return clientDoc.snapshotChanges().pipe(map(action => {
      if (action.payload.exists === false) {
        return null;
      } else {
        const data = action.payload.data() as Client;
        data.staged_trainings = [];
        data.saved_trainings = [];

        this.client = data;
        return data;
      }
    }));
  }


  queryTrainings(clientDoc: AngularFirestoreDocument<Client>, trainingType: string, isFinished: boolean): Observable<Training[]> {
    return clientDoc.collection<Training>('trainings', ref => ref.where('is_completed', '==', isFinished))
      .snapshotChanges().pipe(map(training => {
        return training.map(action => {

          let data = new Training();
          data = action.payload.doc.data() as Training;
          data.training_id = action.payload.doc.id;
          data.created_at = action.payload.doc.get('created_at').toDate();
          data.deadline = action.payload.doc.get('deadline').toDate();
          if (isFinished) {
            data.completed_at = action.payload.doc.get('completed_at').toDate();
          }
          data.notes = action.payload.doc.get('notes');
          data.trainer = action.payload.doc.get('trainer');
          data.exercise_sequence = action.payload.doc.get('exercise_sequence');
          data.feedback = action.payload.doc.get('feedback');
          data.is_completed = action.payload.doc.get('is_completed');
          data.is_free_training = action.payload.doc.get('is_free_training');
          data.average_scores = action.payload.doc.get('average_scores');

          // instantiate exercise and score lists
          data.exercises = new Exercises();
          if (isFinished) {
            data.ballScores = [];
            data.matrixScores = [];
            data.memory3DScores = [];
            data.shadowScores = [];
            data.dodgeScores = [];
          }

          // iterate over every exercise type by reading the exercise (sub)-collections
          clientDoc.collection('trainings').doc(`${action.payload.doc.id}`).collection<BallEx>('ball').valueChanges()
            .subscribe(excs => {
              return excs.map(ex => {
                data.exercises.ballExcs.push(ex);
                if (isFinished) {
                  data.ballScores.push(ex.output_params.score);

                }

              });
            });
          clientDoc.collection('trainings').doc(`${action.payload.doc.id}`).collection<MatrixEx>('matrix').valueChanges()
            .subscribe(excs => {
              return excs.map(ex => {
                data.exercises.matrixExcs.push(ex);
                if (isFinished) {
                  data.matrixScores.push(ex.output_params.score);

                }
              });
            });
          clientDoc.collection('trainings').doc(`${action.payload.doc.id}`).collection<Memory3DEx>('memory').valueChanges()
            .subscribe(excs => {
              return excs.map(ex => {
                data.exercises.memory3DExcs.push(ex);
                if (isFinished) {
                  data.memory3DScores.push(ex.output_params.score);
                }
              });
            });
          clientDoc.collection('trainings').doc(`${action.payload.doc.id}`).collection<ShadowEx>('shadow').valueChanges()
            .subscribe(excs => {
              return excs.map(ex => {
                data.exercises.shadowExcs.push(ex);
                if (isFinished) {
                  data.shadowScores.push(ex.output_params.score);
                }
              });
            });
          clientDoc.collection('trainings').doc(`${action.payload.doc.id}`).collection<DodgeEx>('dodge').valueChanges()
            .subscribe(excs => {
              return excs.map(ex => {
                data.exercises.dodgeExcs.push(ex);
                if (isFinished) {
                  for ( const e of data.exercises.dodgeExcs) {
                    data.dodgeScores.push(e.output_params.score);
                  }

                }
              });
            });
          return data;
        });
      }));
  }

  stageNewTraining(training: Training, clientID: string): Promise<boolean> {
    const trainingsDoc = this.afs.collection('users').doc(`${clientID}`).collection('trainings').doc<Training>(`${training.training_id}`);
    // create new training document with its metadata
    return trainingsDoc.set(
      {
        training_id: training.training_id,
        trainer: training.trainer,
        notes: training.notes,
        created_at: training.created_at,
        deadline: training.deadline,
        is_running: training.is_running,
        is_completed: training.is_completed,
        is_free_training: false,
        exercise_sequence: training.exercise_sequence,
        /*matrixScores: [],
        sortSCores: [],
        shadowScores: [],
        ballScores: [],
        memory3DScores: [],
        dodgeScores: []*/

      }).then(r => ClientService.writeExercises(trainingsDoc, training.exercises, training.exercise_sequence, false));

  }

  incrementTainingsCounter(previousCounter: number[], clientID: string): void {
    const clientDoc = this.afs.collection('users').doc(`${clientID}`);
    // create new training document with its metadata
    clientDoc.update(
      {
        trainings_counter: {
          count_staged_trainings: previousCounter[0] + 1,
          count_saved_trainings: previousCounter[1]
        }
      });
  }

  decrementTainingsCounter(previousCounter: number[], clientID: string): void {
    const clientDoc = this.afs.collection('users').doc(`${clientID}`);
    // create new training document with its metadata
    clientDoc.update(
      {
        trainings_counter: {
          count_staged_trainings: previousCounter[0] - 1,
          count_saved_training: previousCounter[1]
        }
      });
  }

  updateTraining(training: Partial<Training>, clientID: string): Promise<boolean> {
    const trainingsDoc = this.afs.collection('users').doc(`${clientID}`).collection('trainings').doc<Training>(`${training.training_id}`);
    // create new training document with its metadata
    return trainingsDoc.update(
      {
        trainer: training.trainer,
        notes: training.notes,
        created_at: training.created_at,
        deadline: training.deadline,
        exercise_sequence: training.exercise_sequence
      }).then(r => ClientService.writeExercises(trainingsDoc, training.exercises, training.exercise_sequence, true));

  }

  deleteTraining(clientId: string, trainingId: string, previousCounter: number[]): void {
    // delete training document
    this.afs.doc(`users/${clientId}`).collection('trainings').doc(`${trainingId}`).delete().then(r => {
      // delete reference documents in sub-collections
      this.afs.doc(`users/${clientId}`).collection('trainings').doc(`${trainingId}`).collection('matrix').get()
        .toPromise().then((querySnapshot) => {
        querySnapshot.forEach((doc) => doc.ref.delete());
      });
      this.afs.doc(`users/${clientId}`).collection('trainings').doc(`${trainingId}`).collection('memory').get()
        .toPromise().then((querySnapshot) => {
        querySnapshot.forEach((doc) => doc.ref.delete());
      });
      this.afs.doc(`users/${clientId}`).collection('trainings').doc(`${trainingId}`).collection('shadow').get()
        .toPromise().then((querySnapshot) => {
        querySnapshot.forEach((doc) => doc.ref.delete());
      });
      this.afs.doc(`users/${clientId}`).collection('trainings').doc(`${trainingId}`).collection('ball').get()
        .toPromise().then((querySnapshot) => {
        querySnapshot.forEach((doc) => doc.ref.delete());
      });
      this.afs.doc(`users/${clientId}`).collection('trainings').doc(`${trainingId}`).collection('dodge').get()
        .toPromise().then((querySnapshot) => {
        querySnapshot.forEach((doc) => doc.ref.delete());
      });
    });
    this.decrementTainingsCounter([previousCounter[0], previousCounter[1]], clientId);
  }


// TODO : check for Promise >> add .then(return true) & change return type void to Promise<void>
  newClient(client: Client, docId: string): void {
    this.clientsCollection.doc(`${docId}`).set(client);
  }


// TODO : check for Promise >> add .then(return true) & change return type void to Promise<void>
  updateClient(client: Client): void {
    this.clientDoc = this.afs.doc(`users/${client.id}`);
    this.clientDoc.update(client);
  }


  deleteClient(client: Client): void {
    this.clientDoc = this.afs.doc(`users/${client.id}`);
    this.clientDoc.delete().then(r => {
      this.clientDoc.collection('trainings').get().toPromise().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete()
            .then(r => {
              this.clientDoc.collection('trainings').doc(`${doc.get('training_id')}`).collection('matrix').get()
                .toPromise().then((querySnapshot) => {
                querySnapshot.forEach((doc) => doc.ref.delete());
              });
              this.clientDoc.collection('trainings').doc(`${doc.get('training_id')}`).collection('memory').get()
                .toPromise().then((querySnapshot) => {
                querySnapshot.forEach((doc) => doc.ref.delete());
              });
              this.clientDoc.collection('trainings').doc(`${doc.get('training_id')}`).collection('shadow').get()
                .toPromise().then((querySnapshot) => {
                querySnapshot.forEach((doc) => doc.ref.delete());
              });
              this.clientDoc.collection('trainings').doc(`${doc.get('training_id')}`).collection('ball').get()
                .toPromise().then((querySnapshot) => {
                querySnapshot.forEach((doc) => doc.ref.delete());
              });
              this.clientDoc.collection('trainings').doc(`${doc.get('training_id')}`).collection('dodge').get()
                .toPromise().then((querySnapshot) => {
                querySnapshot.forEach((doc) => doc.ref.delete());
              });
            });
        });
      });
    });
  }
}
