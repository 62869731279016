import {Component, ViewChild, Inject, OnDestroy, OnInit} from '@angular/core';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import {ClientService} from '../../services/client.service';
import {TrainerService} from '../../services/trainer.service';
import {AuthService} from '../../services/auth.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Trainer} from '../../models/Trainer';
import {Client} from '../../models/Client';

import {ActivatedRoute, Router} from '@angular/router';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend
} from 'ng-apexcharts';
import {AngularFirestore} from '@angular/fire/firestore';
import firebase from 'firebase';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: any; // ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})


export class ClientDashboardComponent implements OnInit, OnDestroy {
  trainer = new Trainer();
  users: Client[];
  clientId: string;
  private clientDashboardUrl: string;
  client = new Client();

  stagedLoaded: Promise<boolean>;
  savedLoaded: Promise<boolean>;
  clientLoaded: Promise<boolean>;

  unsubscribe: Subject<void> = new Subject();

// Radar
public radarChartOptions: RadialChartOptions = {
  responsive: true,
  legend: {
    position: 'bottom'
  }
};
public radarChartLabels: Label[] = ['Reaktion', 'Gedächtnis', 'Räumliches Denken', 'Hand-Auge-Koordination', 'Ausweichtraining'];

public radarChartData: ChartDataSets[] = [
  { data: [65, 59, 90, 81, 150], label: 'Letztes Training'},
  { data: [28, 48, 40, 19, 38], label: 'Durchschnitt' }
];
public radarChartType: ChartType = 'radar';

  constructor(public clientService: ClientService,
              private trainerService: TrainerService,
              private authService: AuthService,
              private afs: AngularFirestore,
              public router: Router,
              private actRoute: ActivatedRoute,
              private modalService: NgbModal,
              config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false; }

  ngOnInit(): void {
    this.authService.getAuth().pipe(takeUntil(this.unsubscribe)).subscribe(auth => {
      if (auth) {
        this.trainerService.getTrainer(auth.uid).pipe(takeUntil(this.unsubscribe)).subscribe(trainer => {
          this.trainer = trainer;
          this.users = this.clientService.getClients(this.trainer.user_list);

          // get clientID from URL
          this.clientId =  this.actRoute.snapshot.params.id;

          // set client document
          const clientDoc = this.afs.doc<Client>(`users/${this.clientId}`);

          // get client specific dataset
          this.clientService.getClientDataset(clientDoc).subscribe(client => {
              this.clientService.queryTrainings(clientDoc, 'staged', false).subscribe(trs => {
                client.staged_trainings = trs;
                this.stagedLoaded = Promise.resolve(true);
              });
              this.clientService.queryTrainings(clientDoc, 'saved', true).subscribe(trs => {
                client.saved_trainings = trs;
                this.savedLoaded = Promise.resolve(true);
              });

              this.client = client;
              this.clientLoaded = Promise.resolve(true); // Setting the Promise as resolved after we have the needed data
            },
            err => {
            },
            () => {
            });
        });
      }

    });
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.clientService.unsubscribe.next();
    this.clientService.unsubscribe.complete();

  }

  deleteClient(name: string[]): void {
    if (confirm('Are you sure to delete all data and account of:  ' + name)) {
      this.clientService.deleteClient(this.client);
      this.trainerService.removeUserFromTrainersUsrList(this.client.id);
      // TODO: delete google account


      const user = firebase.auth().currentUser;

// TODO(you): prompt the user to re-provide their sign-in credentials
/*      const credential = promptForCredentials();

      user.reauthenticateWithCredential(credential).then(() => {
        // User re-authenticated.
      }).catch((error) => {
        // An error ocurred
        // ...
      });

      user.delete().then(() => {
        // User deleted.
      }).catch((error) => {
        // An error ocurred
        // ...
      });
*/
    }
  }

  navigateToEditor(): void {
    const stagedTrainingsCounter = this.client.staged_trainings.length;
    const savedTrainingsCounter = this.client.saved_trainings.length;
    this.router.navigate([`client/${this.clientId}/trainings-editor`],
      {state: {stagedTrainingsCounter, savedTrainingsCounter}});
  }

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}


