import {Component, OnInit, ViewChild} from '@angular/core';
import Chart, {ChartPointOptions, RadialChartOptions} from 'chart.js';
import {ChartDataSets, ChartType, ChartOptions, ScaleType, ChartElementsOptions, ChartLineOptions} from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {takeUntil, toArray} from 'rxjs/operators';
import {Client} from '../../models/Client';
import {ClientService} from '../../services/client.service';
import {TrainerService} from '../../services/trainer.service';
import {AuthService} from '../../services/auth.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashMessagesService} from 'flash-messages-angular';
import {DatePipe} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Trainer} from '../../models/Trainer';
import {range, Subject} from 'rxjs';
import {Training} from '../../models/Training';
import {TrainingsConverterService} from '../../services/trainings-converter.service';
import {EditorExercise} from '../trainingseditor/trainingseditor.component';


@Component({
  selector: 'app-training-insights',
  templateUrl: './training-insights.component.html',
  styleUrls: ['./training-insights.component.scss']
})
export class TrainingInsightsComponent implements OnInit {
  // TODO: check https://remotestack.io/chart-js-in-angular-with-ng2-charts-examples/
  // TODO check: https://www.chartjs.org/docs/latest/samples/other-charts/radar.html
/*
  // Bar-Plot
  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: Label[] = ['Reaktion', 'Gedächtnis', 'Räumliches Denken', 'Hand-Auge-Koordination', 'Ausweichübung'];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];

  barChartData: ChartDataSets[] = [
    { data: [25, 45, 15, 25, 100], label: 'Beginner' },
    { data: [45, 37, 60, 70, 46], label: 'Einfach' },
    { data: [70, 37, 60, 45, 46], label: 'Medium' },
    { data: [45, 60, 45, 70, 46], label: 'Schwer' },
    { data: [70, 37, 60, 70, 46], label: 'Extrem' },
  ];*/

  // class member
  trainer = new Trainer();
  users: Client[];

  client = new Client();
  clientId: string;
  private currTrainingID: string;
  currTrainingIndex = 0;

  routeToEditorBlocked = false;

  clientLoaded: Promise<boolean>;

  unsubscribe: Subject<void> = new Subject();

  // Specific Plot-member
  // Bar-Plot (0) - mean scores
  bar0ChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
    },
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          stepSize: 25,
          max : 150,
        }
      }]
    }
  };
  bar0ChartLabels: Label[] = [];
  bar0ChartType: ChartType = 'bar';
  bar0ChartLegend = true;
  bar0ChartPlugins = [];

  bar0ChartColors: Color[] = [
    { backgroundColor: 'rgb(247,153,175)'},
    { backgroundColor: 'rgb(126,191,237)'},
    { backgroundColor: 'rgb(247,218,148)'},
    { backgroundColor: 'rgb(139,209,211)'},
    { backgroundColor: 'rgb(221,151,247)'},
  ];

  bar0ChartData: ChartDataSets[] = [
    { data: [], label: 'Reaktion', backgroundColor: 'rgb(247,153,175)' },
    { data: [], label: 'Gedächtnis' },
    { data: [], label: 'Räumliches Denken' },
    { data: [], label: 'Hand-Auge-Koordination' },
    { data: [], label: 'Ausweichübung' },


  ];

  // Bar-Plot (1)
  bar1ChartOptions: ChartOptions = {
    responsive: true,
    legend: { position: 'bottom' },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
          // display: false
          stepSize: 10,
          max : 150,
        }
      }]
    }
  };
  bar1ChartLabels: Label[] = [];
  bar1ChartType: ChartType = 'horizontalBar';
  bar1ChartLegend = true;
  bar1ChartPlugins = [];

  bar1ChartColors: Color[] = [
    { // red
      backgroundColor: 'rgb(247,153,175)',
    },
  ];

  bar1ChartData: ChartDataSets[] = [
    { data: [], label: 'Reaktion',
      barThickness: 18,
    }
  ];

  // Bar-Plot (2)
  bar2ChartOptions: ChartOptions = {
    responsive: true,
    legend: { position: 'bottom' },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
          // display: false
          stepSize: 10,
          max : 150,
        }
      }]
    }
  };
  bar2ChartLabels: Label[] = [];
  bar2ChartType: ChartType = 'horizontalBar';
  bar2ChartLegend = true;
  bar2ChartPlugins = [];

  bar2ChartColors: Color[] = [
    { // blue
      backgroundColor: 'rgb(126,191,237)',
    },
  ];

  bar2ChartData: ChartDataSets[] = [
    { data: [25, 45, 15, 25, 100], label: 'Gedächtnis',
      barThickness: 18,
    }

  ];

  // Bar-Plot (3)
  bar3ChartOptions: ChartOptions = {
    responsive: true,
    legend: { position: 'bottom' },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
          // display: false
          stepSize: 10,
          max : 150,
        }
      }]
    }
  };
  bar3ChartLabels: Label[] = [];
  bar3ChartType: ChartType = 'horizontalBar';
  bar3ChartLegend = true;
  bar3ChartPlugins = [];

  bar3ChartColors: Color[] = [
    { // yellow
      backgroundColor: 'rgb(247,218,148)',
    },
  ];

  bar3ChartData: ChartDataSets[] = [
    { data: [25, 45, 15, 25, 100], label: 'Räumliches Denken',
      barThickness: 18,
    }

  ];

  // Bar-Plot (4)
  bar4ChartOptions: ChartOptions = {
    responsive: true,
    legend: { position: 'bottom' },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
          // display: false
          stepSize: 10,
          max : 150,
        }
      }],
      yAxes: [{
        ticks: {
          suggestedMin: 0,
          autoSkip: false,
        }
      }]
    }
  };
  bar4ChartLabels: Label[] = [];
  bar4ChartType: ChartType = 'horizontalBar';
  bar4ChartLegend = true;
  bar4ChartPlugins = [];

  bar4ChartColors: Color[] = [
    { // red
      backgroundColor: 'rgb(139,209,211)',
    },
  ];

  bar4ChartData: ChartDataSets[] = [
    { data: [25, 45, 15, 25, 100], label: 'Hand-Auge-Koordination',
      barThickness: 18,
      // barPercentage: 0.5,
      // categoryPercentage: 0.2

    }

  ];

  // Bar-Plot (5)
  bar5ChartOptions: ChartOptions = {
    responsive: true,
    legend: { position: 'bottom' },
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
          // display: false
          stepSize: 10,
          max : 150,
        }
      }]
    }
  };
  bar5ChartLabels: Label[] = [];
  bar5ChartType: ChartType = 'horizontalBar';
  bar5ChartLegend = true;
  bar5ChartPlugins = [];

  bar5ChartColors: Color[] = [
    { // red
      backgroundColor: 'rgb(221,151,247)',
    },
  ];

  bar5ChartData: ChartDataSets[] = [
    { data: [25, 45, 15, 25, 100], label: 'Ausweichübung',
      barThickness: 18,
    }

  ];

  // Line-Plot
  lineChartData: ChartDataSets[] = [
    { data: [], label: 'Reaktion' , lineTension: 0, fill: false},
    { data: [], label: 'Gedächtnis', lineTension: 0, fill: false},
    { data: [], label: 'Räumliches Denken', lineTension: 0, fill: false},
    { data: [], label: 'Hand-Auge-Koordination', lineTension: 0, fill: false},
    { data: [], label: 'Ausweichübung', lineTension: 0, fill: false},

  ];

  lineChartLabels: Label[] = ['Training 1', 'Training 2', 'Training 3', 'Training 4', 'Traininge 5', 'Training 6'];

  lineChartOptions = {
    responsive: true,
    scales: {xAxes: [{
        ticks: {
          beginAtZero: true,
          // display: false
          stepSize: 10
        }
      }]
    },
    lineTension: 0,
  };


  lineChartColors: Color[] = [
    { // red
      borderColor: 'rgb(247,153,175)',
      pointBackgroundColor: 'rgb(247,153,175)',
      pointBorderColor: 'rgba(148,159,177,1)',
      pointHoverBorderColor: 'rgba(148,159,177,1)'
    },
    {
      borderColor: 'rgb(126,191,237)',
      pointBackgroundColor: 'rgb(126,191,237)',
      pointBorderColor: 'rgba(148,159,177,1)',
      pointHoverBorderColor: 'rgba(148,159,177,1)'
    },
    {
      borderColor: 'rgb(247,218,148)',
      pointBackgroundColor: 'rgb(247,218,148)',
      pointBorderColor: 'rgba(148,159,177,1)',
      pointHoverBorderColor: 'rgba(148,159,177,1)'
    },
    {
      borderColor: 'rgb(139,209,211)',
      pointBackgroundColor: 'rgb(139,209,211)',
      pointBorderColor: 'rgba(148,159,177,1)',
      pointHoverBorderColor: 'rgba(148,159,177,1)'
    },
    {
      borderColor: 'rgb(221,151,247)',
      pointBackgroundColor: 'rgb(212,151,247)',
      pointBorderColor: 'rgba(148,159,177,1)',
      pointHoverBorderColor: 'rgba(148,159,177,1)'
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType: ChartType = 'line';

  // Radar
  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom'
    }
  };

  public radarChartLabels: Label[] = ['Reaktion', 'Gedächtnis', 'Räumliches Denken', 'Hand-Auge-Koordination', 'Ausweichtraining'];

  public radarChartData: ChartDataSets[] = [
    { data: [65, 59, 90, 81, 150], label: 'Letztes Training'},
    { data: [28, 48, 40, 19, 38], label: 'Durchschnitt' }
  ];
  public radarChartType: ChartType = 'radar';


  constructor(public clientService: ClientService,
              private trainerService: TrainerService,
              private trainingsConverter: TrainingsConverterService,
              private authService: AuthService,
              private afs: AngularFirestore,
              public router: Router,
              private flashMessage: FlashMessagesService,
              private actRoute: ActivatedRoute,
              public datepipe: DatePipe) {}


  ngOnInit(): void {

    this.currTrainingID = history.state.trainingID;

    this.authService.getAuth().pipe(takeUntil(this.unsubscribe)).subscribe(auth => {
      if (auth) {
        this.trainerService.getTrainer(auth.uid).pipe(takeUntil(this.unsubscribe)).subscribe(trainer => {
          this.trainer = trainer;
          this.users = this.clientService.getClients(this.trainer.user_list);

          // get clientID from URL
          this.clientId =  this.actRoute.snapshot.params.id;

          // set client document
          const clientDoc = this.afs.doc<Client>(`users/${this.clientId}`);

          // get client specific dataset
          this.clientService.getClientDataset(clientDoc).subscribe(client => {
              this.clientService.queryTrainings(clientDoc, 'staged', false).subscribe(trs => {
                client.staged_trainings = trs;
                client.staged_trainings.sort((a, b) => (a.deadline > b.deadline) ? 1 : -1);
              });
              this.clientService.queryTrainings(clientDoc, 'saved', true).subscribe(trs => {
                client.saved_trainings = trs;
                client.saved_trainings.sort((a, b) => (a.completed_at > b.completed_at) ? 1 : -1);
                this.currTrainingIndex = client.saved_trainings.findIndex(i => i.training_id === this.currTrainingID);

                // load scores of last training
                this.bar1ChartData[0].data = this.client.saved_trainings[this.currTrainingIndex].matrixScores;
                this.bar2ChartData[0].data = this.client.saved_trainings[this.currTrainingIndex].memory3DScores;
                this.bar3ChartData[0].data = this.client.saved_trainings[this.currTrainingIndex].shadowScores;
                this.bar4ChartData[0].data = this.client.saved_trainings[this.currTrainingIndex].ballScores;
                this.bar5ChartData[0].data = this.client.saved_trainings[this.currTrainingIndex]. dodgeScores;

                // load average scores of last training
                this.bar0ChartData[0].data.push(this.client.saved_trainings[this.currTrainingIndex].average_scores.matrix);
                this.bar0ChartData[1].data.push(this.client.saved_trainings[this.currTrainingIndex].average_scores.memory3D);
                this.bar0ChartData[2].data.push(this.client.saved_trainings[this.currTrainingIndex].average_scores.shadow);
                this.bar0ChartData[3].data.push(this.client.saved_trainings[this.currTrainingIndex].average_scores.ball);
                this.bar0ChartData[4].data.push(this.client.saved_trainings[this.currTrainingIndex].average_scores.dodge);

                // load average scores of further trainings and preparing data for plots
                for (const i of this.client.saved_trainings) {
                  if (! i.average_scores.matrix || i.average_scores.matrix === 0) {
                    this.lineChartData[0].data.push(NaN);
                  } else {
                    this.lineChartData[0].data.push(i.average_scores.matrix);
                  }
                  if (! i.average_scores.memory3D || i.average_scores.memory3D === 0) {
                    this.lineChartData[1].data.push(NaN);
                  } else {
                    this.lineChartData[1].data.push(i.average_scores.memory3D);
                  }
                  if (! i.average_scores.shadow || i.average_scores.shadow === 0) {
                    this.lineChartData[2].data.push(NaN);
                  } else {
                    this.lineChartData[2].data.push(i.average_scores.shadow);
                  }
                  if (! i.average_scores.ball || i.average_scores.ball === 0) {
                    this.lineChartData[3].data.push(NaN);
                  } else {
                    this.lineChartData[3].data.push(i.average_scores.ball);
                  }
                  if (! i.average_scores.dodge || i.average_scores.dodge === 0) {
                    this.lineChartData[4].data.push(NaN);
                  } else {
                    this.lineChartData[4].data.push(i.average_scores.dodge);
                  }
                }

                let cntMatrix = 0;
                let cntMemory = 0;
                let cntShadow = 0;
                let cntBall = 0;
                let cntDodge = 0;

                this.client.saved_trainings[this.currTrainingIndex].exercise_sequence.
                forEach(e => {
                    if (e.includes('matrix')) {
                    cntMatrix += 1;
                    this.bar1ChartLabels.push(cntMatrix.toString());
                  } else if (e.includes('memory')) {
                    cntMemory += 1;
                    this.bar2ChartLabels.push(cntMemory.toString());
                  } else if (e.includes('shadow')) {
                    cntShadow += 1;
                    this.bar3ChartLabels.push(cntShadow.toString());
                  } else if (e.includes('ball')) {
                    cntBall += 1;
                    this.bar4ChartLabels.push(cntBall.toString());
                  } else if (e.includes('dodge')) {
                    cntDodge += 1;
                    this.bar5ChartLabels.push(cntDodge.toString());
                    }
                });
              });

              this.client = client;

              // Setting the Promise as resolved after we have the needed data
              this.clientLoaded = Promise.resolve(true);
            },
            err => {},
            () => {});
        });

        /*this.clientLoaded.then( c => {
          console.log('%%%%%%%%%%%%%%%%%%%')
          console.log(this.client.saved_trainings[this.currTrainingIndex].matrixScores.length)
          this.lineChart = new Chart( 'canvas', {
            type: 'bar',
            data: {
              labels: ['1', '2', '3'],

              datasets: [
                {
                  data: this.client.saved_trainings[this.currTrainingIndex].matrixScores,
                  label: 'Reaktion',
                  // borderColor: '#3cb371',
                  backgroundColor: 'rgb(247,153,175)',
                }
              ]
            },
            options: {
              responsive: true,
              legend: {
                display: true
              },
              scales: {
                xAxes: [{
                  display: true
                }],
                yAxes: [{
                  display: true
                }],
              }
            }
          });
        });*/
      }
    });
  }
}
