import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../services/client.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'flash-messages-angular';
import { Client } from '../../models/Client';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {
  id: string;
  client: Client;
  hasBalance = false;
  showBalanceUpdateInput = false;

  constructor(
    private clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService
  ) { }

  ngOnInit(): void {
    // Get id from url
    this.id = this.route.snapshot.params.id;
    // Get client
    // this.clientService.getClientOLD(this.id).subscribe(client => {
      /*if (client != null) {
        if (client.balance > 0) {
          this.hasBalance = true;
        }
      }*/

      // this.client = client;
    // });
  }

  updateBalance(): void {
    this.clientService.updateClient(this.client);
    this.flashMessage.show('Balance updated', {cssClass: 'alert-success', timeout: 4000});
  }

  onDeleteClick(): void {
    if (confirm('Are you sure?')) {
      this.clientService.deleteClient(this.client);
      this.flashMessage.show('Client removed', {cssClass: 'alert-success', timeout: 4000});
      this.router.navigate(['/']);
    }
  }

}
