import { MatrixEx } from './MatrixEx';
import { ShadowEx } from './ShadowEx';
import { SortEx } from './SortEx';
import { BallEx } from './BallEx';
import { Memory3DEx } from './Memory3DEx';
import {DodgeEx} from './DodgeEx';



export class Exercises {

    matrixExcs: MatrixEx[];
    sortExcs?: SortEx[]; // implement it later if necessary
    shadowExcs: ShadowEx[];
    ballExcs: BallEx[];
    memory3DExcs: Memory3DEx[];
    dodgeExcs: DodgeEx[];

  constructor(){
        this.matrixExcs = [];
        this.sortExcs = [];
        this.shadowExcs = [];
        this.ballExcs = [];
        this.memory3DExcs = [];
        this.dodgeExcs = [];
    }

}
