import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { EditorExercise } from '../../pages/trainingseditor/trainingseditor.component';

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss']
})
export class ExerciseComponent implements OnInit {

  @Input() exercise!: EditorExercise;
  @Output() edit = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
