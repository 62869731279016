<div class="container">
  <app-heading link="/clients">Client Dashboard</app-heading>

  <div class=my-4>
    <div class="col d-flex justify-content-center">
      <img src="https://toppng.com/public/uploads/thumbnail/blue-person-icon-blue-person-icon-11562903982ibmz5x4ndc.png" width="100" class="p-4 rounded">
    </div>
    <h4 *ngIf="clientService.client" class="text-center">{{ client.first_name }} {{ client.last_name }}</h4>
  </div>

  <div *ngIf="clientLoaded | async">
    <triple-statistic
      *ngIf="client.staged_trainings.length == 1 && client.saved_trainings.length == 1"
      first_label="Training offen"
      second_label="Training abgeschlossen"
      third_label="Ges. Trainingszeit"
      [first_number]="client.staged_trainings.length"
      [second_number]="client.saved_trainings.length"
      third_number="60 h #">
    </triple-statistic>
    <triple-statistic
      *ngIf="client.staged_trainings.length == 1 && client.saved_trainings.length > 1"
      first_label="Training offen"
      second_label="Trainings abgeschlossen"
      third_label="Ges. Trainingszeit"
      [first_number]="client.staged_trainings.length"
      [second_number]="client.saved_trainings.length"
      third_number="60 h #">
    </triple-statistic>
    <triple-statistic
      *ngIf="(client.staged_trainings.length == 0 || client.staged_trainings.length > 1) && client.saved_trainings.length == 1"
      first_label="Trainings offen"
      second_label="Training abgeschlossen"
      third_label="Ges. Trainingszeit"
      [first_number]="client.staged_trainings.length"
      [second_number]="client.saved_trainings.length"
      third_number="60 h #">
    </triple-statistic>
    <triple-statistic
      *ngIf="(client.staged_trainings.length == 0 || client.staged_trainings.length > 1) && client.saved_trainings.length > 1"
      first_label="Trainings offen"
      second_label="Trainings abgeschlossen"
      third_label="Ges. Trainingszeit"
      [first_number]="client.staged_trainings.length"
      [second_number]="client.saved_trainings.length"
      third_number="60 h #">
    </triple-statistic>

    <div class="row mb-5">
      <div class="my-2 px-2 col-md-3 col-6">
        <button class="btn sul-btn" routerLink="/client/edit/{{ client.id }}">
          Mitgliedsprofil bearbeiten
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
          </svg>
        </button>
      </div>
      <div class="my-2 px-2 col-md-3 col-6">
        <button type="button" class="btn sul-btn" routerLink="/clients"
                (click)="this.modalService.open(modal, {centered : true, size: 'lg'})">
          Mitgliedsprofil löschen
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
        </button>
      </div>
      <div class="my-2 px-2 col-md-3 col-6">
        <button type="submit" class="btn sul-btn" routerLink="../training-overview">
          Trainingsübersicht anzeigen
        </button>
      </div>
      <div class="my-2 px-2 col-md-3 col-6">
        <button type="submit" class="btn sul-btn" (click)="navigateToEditor()">
          Neues Training zuweisen
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 chart">
        <div>
          <div>
            <div style="display: block">
              <canvas baseChart
                [datasets]="radarChartData"
                [options]="radarChartOptions"
                [labels]="radarChartLabels"
                [chartType]="radarChartType">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modal let-modal>
  <div>
    <div class="modal-header justify-content-center">
      <h4 class="modal-title">Mitglied löschen</h4>
    </div>
    <div class="modal-body px-5">
      <div class="row justify-content-center py-4">
        Möchten Sie dieses Mitglied wirklich löschen?
      </div>
      <div class="modal-footer pb-0 d-flex justify-content-between">
        <button class="btn sul-btn" (click)="modal.close('Abort click')">Abbrechen</button>
        <button type="submit" class="btn sul-btn" (click)="modal.close('Delete click');
        deleteClient([client.first_name, client.last_name])">Mitglied löschen</button>
      </div>
    </div>
  </div>
</ng-template>
