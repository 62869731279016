<div class="container">

  <app-heading link="../../training-overview">Ergebnis Einzeltraining</app-heading>

  <!--div *ngIf="clientLoaded | async" class="chart-container" style="position: relative; height:40vh; width:70vw">
    <canvas id="canvas">{{ lineChart }}</canvas>
  </div-->

  <div class="row mb-5 justify-content-center">
    <div class="col-5 mb-3">
      <canvas baseChart
              [datasets]="bar0ChartData"
              [options]="bar0ChartOptions"
              [labels]="bar0ChartLabels"
              [colors]="bar0ChartColors"
              [chartType]="bar0ChartType">
      </canvas>
    </div>
  </div>
  <div class="row mb-5 justify-content-center">
    <div class="col-5 mb-3">
      <table class="table sul-table">
        <thead class="thead-inverse">
        <tr class="align-middle">
          <th class="text-center" scope="col">Übung</th>
          <th class="text-center" scope="col">Schwierigkeitsgrad</th>
          <th class="text-center" scope="col">Scores</th>
          <th class="text-center" scope="col">Zeit</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let excs of this.client.saved_trainings[this.currTrainingIndex]" class="my-1 p-2 sul-tr align-middle">
          <td class="text-center text-break">{{excs}}</td>
          <td class="text-center text-break">{{excs}}</td>
          <td class="text-center text-break">{{excs}}</td>
          <td class="text-center text-break">{{excs}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class="row mb-5 justify-content-evenly">
    <div *ngIf="this.client.saved_trainings[this.currTrainingIndex].matrixScores.length > 0" class="col-10 mb-3">
      <canvas baseChart
              height="50"
              [datasets]="bar1ChartData"
              [options]="bar1ChartOptions"
              [labels]="bar1ChartLabels"
              [colors]="bar1ChartColors"
              [chartType]="bar1ChartType">
      </canvas>
    </div>
    <div *ngIf="this.client.saved_trainings[this.currTrainingIndex].memory3DScores.length > 0" class="col-10 mb-3">
      <canvas baseChart
              height="50"
              [datasets]="bar2ChartData"
              [options]="bar2ChartOptions"
              [labels]="bar2ChartLabels"
              [colors]="bar2ChartColors"
              [chartType]="bar2ChartType">
      </canvas>
    </div>
    <div *ngIf="this.client.saved_trainings[this.currTrainingIndex].shadowScores.length > 0" class="col-10 mb-3">
      <canvas baseChart
              height="50"
              [datasets]="bar3ChartData"
              [options]="bar3ChartOptions"
              [labels]="bar3ChartLabels"
              [colors]="bar3ChartColors"
              [chartType]="bar3ChartType">
      </canvas>
    </div>
    <div *ngIf="this.client.saved_trainings[this.currTrainingIndex].ballScores.length > 0" class="col-10 mb-3">
      <canvas baseChart
              height="50"
              [datasets]="bar4ChartData"
              [options]="bar4ChartOptions"
              [labels]="bar4ChartLabels"
              [colors]="bar4ChartColors"
              [chartType]="bar4ChartType">
      </canvas>
    </div>
    <div *ngIf="this.client.saved_trainings[this.currTrainingIndex].dodgeScores.length > 0" class="col-10 mb-3">
      <canvas baseChart
              height="50"
              [datasets]="bar5ChartData"
              [options]="bar5ChartOptions"
              [labels]="bar5ChartLabels"
              [colors]="bar5ChartColors"
              [chartType]="bar5ChartType">
      </canvas>
    </div>
  </div>

  <h2 class="mx-auto text-center">Ergebnisse vergangene Trainings</h2>
  <div>
    <!--fake element that is not visible but helps to center the the text.
      Needs to be left there.
      If size of back button is adjusted, this also needs adjustments!-->
    <div display="hidden">
      <svg width="48" height="40" viewBox="0 0 48 40"></svg>
    </div>
  </div>

  <div class="row justify-content-center mb-5">
    <div class="col-8 chart">
      <div class="chartjs-block">
        <canvas baseChart
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
        </canvas>
      </div>
    </div>
  </div>

  <h2 class="mx-auto text-center">Übersicht Gesamtperformance</h2>
  <div>
    <!--fake element that is not visible but helps to center the the text.
      Needs to be left there.
      If size of back button is adjusted, this also needs adjustments!-->
    <div display="hidden">
      <svg width="48" height="40" viewBox="0 0 48 40"></svg>
    </div>
  </div>

  <div class="row">
    <div class="col-12 chart">
      <div style="display: block">
        <canvas baseChart
                [datasets]="radarChartData"
                [options]="radarChartOptions"
                [labels]="radarChartLabels"
                [chartType]="radarChartType">
        </canvas>
      </div>
    </div>
  </div>
</div>
