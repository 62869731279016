<div class="container">
  <app-heading *ngIf="clientLoaded | async"
    link="../client-dashboard"
    subheading="Sieh dir alle Trainingsaktivitäten von {{clientService.client.first_name}} {{clientService.client.last_name}} an">
    Trainingsübersicht
  </app-heading>
  <h5 class="mb-3 -3 d-flex justify-content-center align-items-center flex-wrap">
    Klicke auf ein Training um dieses zu ändern oder dessen Performance-Parameter einzusehen
  </h5>

  <div *ngIf="clientLoaded | async">
    <double-statistic
      first_label="Offene Trainings:"
      second_label="Absolvierte Trainings:"
      [first_number]="client.staged_trainings.length"
      [second_number]="client.saved_trainings.length">
    </double-statistic>
  </div>

  <h4>Offene Trainings</h4>
  <table *ngIf="clientLoaded | async" class="table sul-table">
    <thead>
        <tr class="align-middle">
          <th class="text-center" scope="col">Erstellt am</th>
          <th class="text-center d-none d-md-table-cell" scope="col">Deadline</th>
          <th class="text-center d-none d-lg-table-cell" scope="col">Notizen</th>
          <th class="text-center" scope="col">Anzahl Übungen</th>
          <th scope="col"> </th>
        </tr>
    </thead>
    <tbody *ngIf="client.staged_trainings?.length > 0;else noStagedTrainings">
      <tr *ngFor="let training of this.client.staged_trainings; let i in client.staged_trainings" class="my-1 p-2 sul-box-table sul-tr align-middle"
          (click)="training.is_running == false && this.routeToEditorBlocked == false ? navigateToEditor(training): ''"><!-- routerLink="../trainings-editor"-->
        <!--td class="text-center text-break">{{ training.created_at.toLocaleString() | date: 'dd.MM.YYYY' }}</td-->
              <td class="text-center text-break">{{ this.datepipe.transform(training.created_at, 'dd.MM.yyyy') }}</td>
        <td class="d-none d-md-table-cell text-center text-break">{{ this.datepipe.transform(training.deadline, 'dd.MM.yyyy') }}</td>
        <td class="d-none d-lg-table-cell text-center text-break">{{ training.notes }}</td>
        <td *ngIf="training.is_running == false" class="text-center text-break">{{ training.exercises.memory3DExcs.length +
               training.exercises.matrixExcs.length +
               training.exercises.shadowExcs.length +
               training.exercises.ballExcs.length +
               training.exercises.dodgeExcs.length }}</td>
        <td *ngIf="training.is_running == true" class="text-center text-break"><em>Training läuft gerade</em></td>
        <td class="sul-xs" *ngIf="training.is_running == false">
          <div class="text-end m-1 py-1">
            <a class="sul-btn my-1 me-2 p-2"
               onClick="" (click)="this.modalService.open(modal, {centered : true, size: 'lg'}); this.rememberTrainingID(training.training_id); this.blockNavToEditor()">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
              </svg>
            </a>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="container">
    <div class="row justify-content-center">
      <div class="col-2 mx-auto">
          <button type="submit" class="btn sul-btn" (click)="navigateToEditor(null)">
            Neues Training zuweisen
          </button>
      </div>
    </div>
  </div>


  <h4 class="my-5">Absolvierte Trainings</h4>
  <table *ngIf="clientLoaded | async and client.saved_trainings?.length > 0;else noSavedTrainings" class="table sul-table">
    <thead class="thead-inverse">
      <tr class="align-middle">
        <th class="text-center" scope="col">Absolviert am</th>
        <th class="text-center d-none d-md-table-cell" scope="col">Deadline</th>
        <th class="text-center d-none d-lg-table-cell" scope="col">Feedback</th>
        <th class="text-center" scope="col">Anzahl Übungen</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let training of this.client.saved_trainings" class="my-1 p-2 sul-box-table clickable-row sul-tr align-middle"
          (click)="navigateToInsights(training.training_id)">
        <td class="text-center text-break">{{ this.datepipe.transform(training.completed_at, 'dd.MM.yyyy') }}</td>
        <td class="d-none d-md-table-cell text-center text-break">{{ this.datepipe.transform(training.deadline, 'dd.MM.yyyy') }}</td>
        <td class="d-none d-lg-table-cell text-center text-break">{{ training.feedback }}</td>
        <td class="text-center text-break">
          {{ training.exercises.memory3DExcs.length +
        training.exercises.ballExcs.length +
        training.exercises.matrixExcs.length +
        training.exercises.sortExcs.length +
        training.exercises.shadowExcs.length }}</td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #noStagedTrainings>
  <hr>
  <h5>Keine offenen Trainings</h5>
</ng-template>

<ng-template #noSavedTrainings>
  <hr>
  <h5>Keine absolvierten Trainings</h5>
</ng-template>

<ng-template #modal let-modal>
  <div>
    <div class="modal-header justify-content-center">
      <h4 class="modal-title">Training löschen</h4>
    </div>
    <div class="modal-body px-5">
      <div class="row justify-content-center py-4">
        Möchten Sie dieses Training wirklich löschen?
      </div>
      <div class="modal-footer pb-0 d-flex justify-content-between">
          <button class="btn sul-btn" (click)="modal.close('Abort click')">Abbrechen</button>
          <button type="submit" class="btn sul-btn" (click)="modal.close('Delete click'); this.clientService.deleteTraining(this.client.id, this.currTrainingID,
        [this.client.staged_trainings.length, this.client.saved_trainings.length]); this.blockNavToEditor()">Training löschen</button>
       </div>
    </div>
  </div>
</ng-template>
