import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import firebase from 'firebase';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceSecondary {

  countAppInstances = 0;

  constructor() {}

  createClient(email: string, password: string): any {

    const config = environment.firebase;

    const secondaryApp = firebase.initializeApp(config, `additionalApp_${this.countAppInstances}`);

    return new Promise((resolve, reject) => {
      secondaryApp.auth().createUserWithEmailAndPassword(email, password)
        .then(userData => {resolve(userData);
                           secondaryApp.auth().signOut();
                           this.countAppInstances += 1;
                           }, err => reject(err));
    });
  }

}
