import { Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'flash-messages-angular';
import { ClientService } from '../../services/client.service';
import { Router } from '@angular/router';

import { Client } from '../../models/Client';
import { Trainer } from '../../models/Trainer';


import { AuthService } from '../../services/auth.service';
import {TrainerService} from '../../services/trainer.service';
import {of} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthServiceSecondary} from '../../services/auth.secondary.service';
import { Target } from '@angular/compiler';

import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;




@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {
  client = new Client();
  handicaps: string[] = [];
  handicapValue = '';
  tempDate: Date = new Date();

  constructor(
    private flashMessage: FlashMessagesService,
    private clientService: ClientService,
    private trainerService: TrainerService,
    private authService: AuthServiceSecondary,
    private router: Router) { }


  ngOnInit(): void {
  }

  onSubmit({value, valid}: { value: Client, valid: boolean }): void {
    if (!valid) {
      // Show error
      this.flashMessage.show('Please fill out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
    } else {
      this.authService.createClient(this.client.e_mail, this.client.e_mail.toString()).then(
        usrCreds => {
          // set new assigned client-ID
          value.id = usrCreds.user.uid;
          // set default values for counters and highscores
          value.trainings_counter = {
            count_saved_trainings: 0,
            count_staged_trainings: 0
          };
          value.personal_highscores = {
            Matrix: 0,
            ThreeDMemory: 0,
            Shadow: 0,
            Ball: 0,
            Dodge: 0,
          };
          // set clients handicaps field from temporary component handicap list
          value.health_data.handicaps = this.handicaps;
          // Convert birthday JS-Date-object to Firestore Timestamp-object
          this.tempDate = new Date(`${this.tempDate}`);
          value.birthday = Timestamp.fromDate(this.tempDate);

          // Add new client document with assigned client-ID
          this.clientService.newClient(value, value.id);
          // Add new client-ID to trainers user-list and update DB
          this.trainerService.addUserToTrainersUsrList(value.id);
      });

      // Show message
      this.flashMessage.show('New client added', {
        cssClass: 'alert-success', timeout: 4000
      });
      // Redirect to dash
      this.router.navigate(['/clients']);
    }
  }

  add_handicap(event: Event): void {
    if ((event.target as HTMLInputElement).value !== ''){
      this.handicaps.push((event.target as HTMLInputElement).value);
    }
    this.handicapValue = '';
  }

  add_handicap_button(): void{
    if (this.handicapValue !== ''){
      this.handicaps.push(this.handicapValue);
    }
    this.handicapValue = '';
  }

  remove_handicap(val: string): void {
    this.handicaps.splice(this.handicaps.indexOf(val, 0), 1);
  }
}
