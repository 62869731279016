import { Time } from '@angular/common';
import number from 'additional_modules/TimeSpan';

export enum DifficultyLevel {

    beginner,
    easy,
    medium,
    hard,
    extreme

}

enum Dimension {

    twoDim,
    threeDim

}

export class InputParams {

    duration: number;
    difficulty: DifficultyLevel;

    score_goal?: number;

}

export class OutputParams {

    score: number;

    start?: Time;
    end?: Time;
    duration?: number;

}

// MATRIX PARAMS
export class MatrixInputs extends InputParams {

    colored_mode: boolean;

    max_time_react?: number;
    num_colors?: number;
    min_combo?: number;
    max_fails?: number;

}

export class MatrixOutputs extends OutputParams {

    num_hits?: number;
    num_missed_hits?: number;
    num_false_color?: number;
    average_time_react?: number;
    best_time_react?: number;
    worst_time_react?: number;

}

// SORT PARAMS
export class SortInputs extends InputParams {

    max_time_react?: number;
    themes?: boolean[]; // bool values ordered by calc,colors,cities,symbols
    min_combo?: number;
    max_fails?: number;

}

export class SortOutputs extends OutputParams {

   num_right?: number;
   num_false?: number;
   average_time_task?: number;
   best_time_task?: number;
   worst_time_task?: number;

}

// SHADOW PARAMS
export class ShadowInputs extends InputParams {

    max_time_task?: number;

}

export class ShadowOutputs extends OutputParams {

    num_right?: number;
    average_time_task?: number;
    best_time_task?: number;
    worst_time_task?: number;

}

// BALL PARAMS
export class BallInputs extends InputParams {

    num_directions?: number;
    ball_frequency?: number;
    ball_velocity?: number;
    max_fails?: number;
    min_combo?: number;
    color_mode?: number;
    move_mode?: boolean;

}

export class BallOutputs extends OutputParams {

    num_hits?: number;
    num_missed_balls?: number;
    num_false_color?: number;

}

// MEMORY3D PARAMS
export class Memory3DInputs extends InputParams {

    //dim?: Dimension;
    //num_pairs?: number;
    multicolor?: boolean;
    objects_move?: boolean;
    color_distraction?: boolean;

}

export class Memory3DOutputs extends OutputParams {

    cnt_pairs?: number;

}

// DODGE PARAMS
export class DodgeInputs extends InputParams {

  // no specific parameters set yet

}

export class DodgeOutputs extends OutputParams {

  // no specific parameters set yet

}
