import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth) { }

  login(email: string, password: string): any {
    return new Promise((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password)
        .then(userData => resolve(userData),  err => reject(err));
    });

  }

  getAuth(): any{
    return this.afAuth.authState.pipe();
  }

  async getCurrentUser(): Promise<any> {
    return (await this.afAuth.currentUser).uid;
  }

  logout(): void {
    this.afAuth.signOut();
  }

  register(email: string, password: string): any {
    return new Promise((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then(userData => resolve(userData), err => reject(err));
    });
  }

  createClient(email: string, password: string): any {
    return new Promise((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then(userData => resolve(userData), err => reject(err));
    });
  }


}
