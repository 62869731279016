import { Component, Input, OnInit } from '@angular/core';

// e.g. <app-heading link="optional routing link other than ../" subheading="Optional text for subtitle">Title of the page</app-heading>
@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnInit {
  @Input() subheading:string;
  @Input() link:string ="../";

  constructor() {
   }

  ngOnInit(): void {
  }

}
