<div class="container">
  <div class="d-flex justify-content-start align-items-center flex-wrap">
    <a routerLink="/clients" class="text-reset text-decoration-none">
      <div class="sul-btn p-2 px-3">

        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left"
             viewBox="0 0 16 16">
          <path fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
      </div>
    </a>
  </div>

  <div class="row">
    <div class="col-md-7 mx-auto">
      <div class="card">
        <div class="sul-box-raised-1 align-items-center d-flex flex-column p-1">
          <div>
            <h4 class="text-center pad">
              Neuer Client
            </h4>
          </div>

          <div>
            <form #clientForm="ngForm" (ngSubmit)="onSubmit(clientForm)" class="my-3 mx-1" (keydown.enter)="$event.preventDefault()">
              <div class="mb-3">
                <label for="e-mail" class="form-label mb-0">*E-Mail</label>
                <input
                  type="email"
                  id="e-mail"
                  name="e_mail"
                  #clientEmail="ngModel"
                  [ngClass]="{'is-invalid':clientEmail.errors && clientEmail.touched}"
                  [(ngModel)]="client.e_mail"
                  class="form-control sul-text-field"
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  required>
                <div [hidden]="!clientEmail.errors?.required" class="invalid-feedback">Email is required</div>
                <div [hidden]="!clientEmail.errors?.pattern" class="invalid-feedback">Email is not valid</div>
              </div>
              <div class="my-3">
                <label for="username" class="form-label mb-0">*Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  #clientUserName="ngModel"
                  [ngClass]="{'is-invalid':clientUserName.errors && clientUserName.touched}"
                  [(ngModel)]="client.username"
                  class="form-control sul-text-field"
                  minlength="3"
                  required>
                <div [hidden]="!clientLastName.errors?.required" class="invalid-feedback">Last Name is required</div>
                <div [hidden]="!clientLastName.errors?.minlength" class="invalid-feedback">Must be at least 3 characters</div>
              </div>
              <div class="my-3">
                <label for="first_name" class="form-label mb-0">*Vorname</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  #clientFirstName="ngModel"
                  [ngClass]="{'is-invalid':clientFirstName.errors && clientFirstName.touched}"
                  [(ngModel)]="client.first_name"
                  class="form-control sul-text-field"
                  minlength="3"
                  required>
                <div [hidden]="!clientFirstName.errors?.required" class="invalid-feedback">First Name is required</div>
                <div [hidden]="!clientFirstName.errors?.minlength" class="invalid-feedback">Must be at least 3 characters</div>
              </div>
              <div class="my-3">
                <label for="last_name" class="form-label mb-0">*Nachname</label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  #clientLastName="ngModel"
                  [ngClass]="{'is-invalid':clientLastName.errors && clientLastName.touched}"
                  [(ngModel)]="client.last_name"
                  class="form-control sul-text-field"
                  minlength="3"
                  required>
                <div [hidden]="!clientLastName.errors?.required" class="invalid-feedback">Last Name is required</div>
                <div [hidden]="!clientLastName.errors?.minlength" class="invalid-feedback">Must be at least 3 characters</div>
              </div>
              <div class="form-group row my-3">
                <label for="gender" class="col-sm-4 col-form-label pt-0">*Geschlecht</label>
                <div id="gender" class="col-sm-8 row">
                  <div id>
                    <input type="radio" class="sul-radio mb-3 me-2" name="gender" value = 'male' [(ngModel)]="client.gender"> männlich
                  </div>
                  <div>
                    <input type="radio" class="sul-radio mb-3 me-2" name="gender" value = 'female' [(ngModel)]="client.gender"> weiblich
                  </div>
                  <div>
                    <input type="radio" class="sul-radio me-2" name="gender" value = 'diverse' [(ngModel)]="client.gender"> divers
                  </div>
                </div>
              </div>
              <div class="my-3">
                <label class="form-label mb-0">*Geburtstag</label>
                <input
                  type="date"
                  name="birthday"
                  #clientBirthday="ngModel"
                  [ngClass]="{'is-invalid':clientBirthday.errors && clientBirthday.touched}"
                  [(ngModel)]="tempDate"
                  class="form-control sul-text-field"
                  required>
                <div [hidden]="!clientBirthday.errors?.required" class="invalid-feedback">Birthday is required</div>
              </div>
              <fieldset ngModelGroup="address">
                <div class="mb-3">
                  <label for="street" class="form-label mb-0">*Straße</label>
                  <input
                    type="text"
                    id ="street"
                    name="street"
                    #clientAddressStreet="ngModel"
                    [ngClass]="{'is-invalid':clientAddressStreet.errors && clientAddressStreet.touched}"
                    ngModel
                    class="form-control sul-text-field"
                    minlength="3"
                    required>
                  <div [hidden]="!clientAddressStreet.errors?.required" class="invalid-feedback">Street is required</div>
                  <div [hidden]="!clientAddressStreet.errors?.minlength" class="invalid-feedback">Must be at least 3 characters</div>
                </div>
                <div class="my-3">
                  <label for="house_number" class="form-label mb-0">*Hausnummer</label>
                  <input
                    type="text"
                    id="house_number"
                    name="house_number"
                    #clientAddressHouseNumber="ngModel"
                    [ngClass]="{'is-invalid':clientAddressHouseNumber.errors && clientAddressHouseNumber.touched}"
                    ngModel
                    class="form-control sul-text-field"
                    required>
                  <div [hidden]="!clientAddressHouseNumber.errors?.required" class="invalid-feedback">House number is required</div>
                </div>
                <div class="my-3">
                  <label for="location" class="form-label mb-0">*Ort</label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    #clientAddressLocation="ngModel"
                    [ngClass]="{'is-invalid':clientAddressLocation.errors && clientAddressLocation.touched}"
                    ngModel
                    class="form-control sul-text-field"
                    minlength="3"
                    required>
                  <div [hidden]="!clientAddressLocation.errors?.required" class="invalid-feedback">Location is required</div>
                  <div [hidden]="!clientAddressLocation.errors?.minlength" class="invalid-feedback">Must be at least 3 characters</div>
                </div>
                <div class="mt-3">
                  <label for="zip_code" class="form-label mb-0">*PLZ</label>
                  <input
                    type="number"
                    id="zip_code"
                    name="zip_code"
                    #clientAddressZipCode="ngModel"
                    [ngClass]="{'is-invalid':clientAddressZipCode.errors && clientAddressZipCode.touched}"
                    ngModel
                    class="form-control sul-text-field"
                    minlength="5"
                    required>
                  <div [hidden]="!clientAddressZipCode.errors?.required" class="invalid-feedback">Zip code is required</div>
                  <div [hidden]="!clientAddressZipCode.errors?.minlength" class="invalid-feedback">Must be at least 5 characters</div>
                </div>
              </fieldset>
              <div class="my-3">
                <label for="phone" class="form-label mb-0">*Telefon</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  #clientPhone="ngModel"
                  [ngClass]="{'is-invalid':clientPhone.errors && clientPhone.touched}"
                  [(ngModel)]="client.phone"
                  class="form-control sul-text-field"
                  minlength="8"
                  required>
                <div [hidden]="!clientPhone.errors?.required" class="invalid-feedback">Phone is required</div>
                <div [hidden]="!clientPhone.errors?.minlength" class="invalid-feedback">Must be at least 8 characters</div>
              </div>
              <fieldset ngModelGroup="health_data">
                <div class="mb-3">
                  <div class="d-flex flex-row flex-wrap">
                    <div *ngFor="let handicap of handicaps">
                      <button type="button"
                              class="btn btn-outline-dark btn-small m-1"
                              (click)="remove_handicap(handicap)">{{ handicap }}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <label for="handicaps" class="form-label mb-0 mt-2">Gesundheitsstatus (Handicap)</label>
                  <div class="input-group">
                    <input
                      type="text"
                      id="handicaps"
                      name="handicaps"
                      #clientHealthDataHandicaps="ngModel"
                      [ngClass]="{'is-invalid':clientHealthDataHandicaps.errors && clientHealthDataHandicaps.touched}"
                      [(ngModel)]= "handicapValue"
                      ngModel
                      (keydown.enter)="add_handicap($event)"
                      class="form-control sul-text-field">
                      <button
                        (click)="add_handicap_button();$event.preventDefault()"
                        class="sul-btn align-middle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                      </button>
                  </div>
                </div>
                <div class="my-3">
                  <label for="height" class="form-label mb-0">Größe in cm</label>
                  <input
                    type="number"
                    id="height"
                    name="height"
                    #clientHealthDataHeight="ngModel"
                    [ngClass]="{'is-invalid':clientHealthDataHeight.errors && clientHealthDataHeight.touched}"
                    ngModel
                    class="form-control sul-text-field"
                    min="120"
                    max="250">
                  <div [hidden]="!clientHealthDataHeight.errors?.min" class="invalid-feedback">Please set min. value of 120 cm</div>
                  <div [hidden]="!clientHealthDataHeight.errors?.max" class="invalid-feedback">Please set value max. value of 250 cm</div>
                </div>
                <div class="my-3">
                  <label for="weight" class="form-label mb-0">Gewicht in kg</label>
                  <input
                    type="number"
                    id="weight"
                    name="weight"
                    #clientHealthDataWeight="ngModel"
                    [ngClass]="{'is-invalid':clientHealthDataWeight.errors && clientHealthDataWeight.touched}"
                    ngModel
                    class="form-control sul-text-field"
                    min="40"
                    max="200">
                  <div [hidden]="!clientHealthDataWeight.errors?.min" class="invalid-feedback">Please set min. value of 40 kg</div>
                  <div [hidden]="!clientHealthDataWeight.errors?.max" class="invalid-feedback">Please set max. value of 200 kg</div>
                </div>
              </fieldset>
              <div class="d-flex justify-content-center">
                <input type="submit" value="Neuen Clienten aufnehmen" class="sul-btn btn"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
