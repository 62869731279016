import { Component, Input, OnInit } from '@angular/core';

/* e.g.
  <triple-statistic
    first_label="Erster Wert"
    seconde_label="Zweiter Wert"
    third_label="Dritter Wert"
    first_number="12#"
    seconde_number="12#"
    third_number="12#">
  </triple-statistic>
  -----or with variables
  <triple-statistic
    first_label="Erster Wert"
    seconde_label="Zweiter Wert"
    third_label="Dritter Wert"
    [first_number]="countFirst"
    [seconde_number]="countSeconde"
    [third_number]="countThird">
  </triple-statistic>
*/
@Component({
  selector: 'triple-statistic',
  templateUrl: './triple-statistic.component.html',
  styleUrls: ['./triple-statistic.component.scss']
})
export class TripleStatisticComponent implements OnInit {
  @Input() first_label: string;
  @Input() second_label: string;
  @Input() third_label: string;
  @Input() first_number: number;
  @Input() second_number: number; // TODO: change type to number when fields are read by database
  @Input() third_number: string; // TODO: change type to number when fields are read by database

  constructor() {
   }

  ngOnInit(): void {
  }

}
