import {Component, OnDestroy, OnInit} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewExerciseDialogComponent } from '../../components/newExercise-dialog/new-exercise-dialog.component';

import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import {ClientService} from '../../services/client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FlashMessagesService} from 'flash-messages-angular';
import {Training} from '../../models/Training';
import {TrainingsConverterService} from '../../services/trainings-converter.service';
import {DifficultyLevel, InputParams} from '../../models/exercise_models/ExerciseParams';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import {Client} from '../../models/Client';
import {AuthService} from '../../services/auth.service';
import {DatePipe} from '@angular/common';


export enum ExerciseType {
  // default = 'Übungstyp auswählen',
  Matrix = 'Reaktion',
  Memory = 'Gedächtnis',
  Shadow = 'Räumliches Denken',
  Ball = 'Hand-Auge Koordination',
  Dodge = 'Ausweichtraining'
}

export class EditorExercise {
  exerciseType: ExerciseType;
  difficulty: DifficultyLevel = null;
  duration: number = null;
  durMin: number;
  durSec: number;

  // specific matrix exercise params
  colored_mode?: boolean;
  // specific memory exercise params

  // specific shadow exercise params

  // specific ball exercise params


  constructor(exerciseType,
              difficulty,
              duration,
              coloredMode?) {
    this.exerciseType = exerciseType;
    this.difficulty = difficulty;
    this.duration = duration;
    /*if (this.colored_mode) {*/
    this.colored_mode = coloredMode;
    /*}*/
  }
}

@Component({
  selector: 'app-trainingseditor',
  templateUrl: './trainingseditor.component.html',
  styleUrls: ['./trainingseditor.component.scss']
})

export class TrainingseditorComponent implements OnInit {

  closeResult: string;
  clientId: string;
  activeTraining: Training;
  counterStagedTrs: number;
  counterSavedTrs: number;

  exerciseCollection: EditorExercise[] = [];
  currExcsID: number;
  currExcsType: ExerciseType;
  currExcsDiff: DifficultyLevel;
  currExcsDiffAsStr: string;
  currExcsDur: number;
  currExcsDurMin: number;
  currExcsDurSec: number;
  totalTrainingDur = 0;
  totalTrainingDurSec = 0;
  totalTrainingDurMin = 0;
  deadline: Date;
  deadlineAsStr: string;
  private backupExercise: Partial<EditorExercise>;


constructor(public dialog: MatDialog,
            public clientService: ClientService,
            private trainingsConverter: TrainingsConverterService,
            private router: Router,
            private flashMessage: FlashMessagesService,
            private modalService: NgbModal,
            public datepipe: DatePipe,
            private actRoute: ActivatedRoute,
            private authService: AuthService,
            config: NgbModalConfig) {
  config.backdrop = 'static';
  config.keyboard = false;
}

  ngOnInit(): void {
    // get clientID from URL
    this.clientId =  this.actRoute.snapshot.params.id;
    // set active training, whether new or received from parent component
    if (history.state.training) {
      // receive trainings-object from parent component
      this.activeTraining = history.state.training;
      this.deadline = this.activeTraining.deadline;
    } else {
      this.activeTraining = new Training();
    }
    // receive current counter of staged trainings
    this.counterStagedTrs = history.state.stagedTrainingsCounter;
    this.counterSavedTrs = history.state.savedTrainingsCounter;
    // convert trainings in editor- readable format
    this.exerciseCollection = this.trainingsConverter.convertToEditorExcs(this.activeTraining);
    for (const i of this.exerciseCollection) {
      this.totalTrainingDur += i.duration;
      i.durSec = i.duration % 60;
      i.durMin = Math.trunc(i.duration / 60);
    }
    // separate absolute trainingstime in seconds to minutes and seconds
    this.totalTrainingDurMin = this.separateTimeMinSec(this.totalTrainingDur).min;
    this.totalTrainingDurSec = this.separateTimeMinSec(this.totalTrainingDur).sec;

    // convert date-fields for correct rendering
    this.deadlineAsStr = this.datepipe.transform(this.activeTraining.deadline, 'dd.MM.yyyy');

  }

  separateTimeMinSec(TotTimeInSec: number): {min: number, sec: number } {
  return {min: Math.trunc(TotTimeInSec / 60), sec: TotTimeInSec % 60};
  }

  drop(event: CdkDragDrop<EditorExercise[]>): void{
    moveItemInArray(this.exerciseCollection, event.previousIndex, event.currentIndex);
  }

  addExercise(): void {
      this.exerciseCollection.push(new EditorExercise(null, null, null, null));
    }

  deleteExercise(exercise: EditorExercise, excsArray: EditorExercise[]): void {
      const index = excsArray.indexOf(exercise);
      if (index > -1) {
        excsArray.splice(index, 1);
      }
      this.totalTrainingDur -= exercise.duration;
      // separate absolute trainingstime in seconds to minutes and seconds
      this.totalTrainingDurMin = this.separateTimeMinSec(this.totalTrainingDur).min;
      this.totalTrainingDurSec = this.separateTimeMinSec(this.totalTrainingDur).sec;
    }



  onOpenParamsSetting(excs: EditorExercise, exIDx: number, modal): void {
    this.backupExercise = {... this.exerciseCollection[exIDx]};
    this.currExcsID = exIDx;
    this.currExcsType = excs.exerciseType;
    this.currExcsDiff = excs.difficulty;
    this.currExcsDiffAsStr = DifficultyLevel[excs.difficulty];
    this.currExcsDur = excs.duration;
    this.currExcsDurSec = this.currExcsDur % 60;
    this.currExcsDurMin = Math.trunc(this.currExcsDur / 60);
    this.modalService.open(modal, {centered : true, size: 'lg'});
    this.onReadOnChangeExDiff(excs.difficulty);
  }

  onSubmitParams({value, valid}: { value: EditorExercise, valid: boolean}, exIDx: number): void {
  // calc duration from mm:ss to absolute seconds
    value.duration = value.durMin * 60 + value.durSec;
    // assign form inputs to exercise in exercise-collection
    this.exerciseCollection[exIDx] = value;
    // update total trainings time
    this.totalTrainingDur += value.duration;
    this.totalTrainingDur -= this.currExcsDur;
    // separate absolute trainingstime in seconds to minutes and seconds
    this.totalTrainingDurMin = this.separateTimeMinSec(this.totalTrainingDur).min;
    this.totalTrainingDurSec = this.separateTimeMinSec(this.totalTrainingDur).sec;
    // close "edit parameter" - modal window
    this.modalService.dismissAll();

  }

  cancelParamsSetting(exerciseIndex: number): void{
    // specific backup template for matrix-exercises
    if (this.exerciseCollection[exerciseIndex].exerciseType === ExerciseType.Matrix) {
      this.exerciseCollection[exerciseIndex].exerciseType = this.backupExercise.exerciseType;
      this.exerciseCollection[exerciseIndex].difficulty = this.backupExercise.difficulty;
      this.exerciseCollection[exerciseIndex].duration = this.backupExercise.duration;
      this.exerciseCollection[exerciseIndex].colored_mode = this.backupExercise.colored_mode;
    }
    else {
      this.exerciseCollection[exerciseIndex].exerciseType = this.backupExercise.exerciseType;
      this.exerciseCollection[exerciseIndex].difficulty = this.backupExercise.difficulty;
      this.exerciseCollection[exerciseIndex].duration = this.backupExercise.duration;
    }

  }

  saveTraining(): void {
    // set training creation date & assign it to trainings document name
    const d = new Date();
    // TODO: refactor it as follows:
      // const d: number = Date.now();
    const trainingID = `${d.getFullYear()}${d.getMonth()}${d.getDay()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}${d.getMilliseconds()}`;
    // convert EditorExercise Params to training - structure & send dataset to db
    console.log(this.exerciseCollection);
    const convertedTraining = this.trainingsConverter.convertToTraining(this.exerciseCollection).training;
    // set trainings-metadata
    convertedTraining.training_id = trainingID;
    this.authService.getCurrentUser().then(uid => {
      convertedTraining.trainer = uid;
      convertedTraining.notes = this.activeTraining.notes;
      convertedTraining.created_at = new Date();

      // Convert deadline JS-Date-object to Firestore Timestamp-object
      this.deadline = new Date(`${this.deadline}`);
      convertedTraining.deadline = this.deadline;
      convertedTraining.is_running = false;
      convertedTraining.is_completed = false;
      convertedTraining.exercise_sequence = this.trainingsConverter.convertToTraining(this.exerciseCollection).sequenceList;
      this.clientService.stageNewTraining(convertedTraining, this.clientId).then(r => {

          // Show message
          this.flashMessage.show('Training gespeichert', {
            cssClass: 'alert-success', timeout: 4000
          });
          // increment static trainings counter for staged trainings, if new training set it to zero
          if (! this.counterStagedTrs) {this.counterStagedTrs = 0; }
          if (! this.counterSavedTrs) {this.counterSavedTrs = 0; }
          this.clientService.incrementTainingsCounter([this.counterStagedTrs, this.counterSavedTrs], this.clientId);
          // Redirect to dash
          this.router.navigate([`/client/${this.actRoute.snapshot.params.id}/training-overview`]);
        }
      );
    });
  }

  updateTraining(trainingID: string): void {
    const convertedTraining = this.trainingsConverter.convertToTraining(this.exerciseCollection).training;
    // set trainings-metadata
    convertedTraining.training_id = trainingID;
    this.authService.getCurrentUser().then(uid => {
      convertedTraining.trainer = uid; // set uid new for case, that trainer changed
      convertedTraining.notes = this.activeTraining.notes; // check for notes input
      convertedTraining.created_at = new Date();
      // Convert deadline JS-Date-object to Firestore Timestamp-object
      this.deadline = new Date(`${this.deadline}`); // check for deadline input
      convertedTraining.deadline = this.deadline;

      convertedTraining.exercise_sequence = this.trainingsConverter.convertToTraining(this.exerciseCollection).sequenceList;
      this.clientService.updateTraining(convertedTraining, this.clientId).then(r => {

          // Show message
          this.flashMessage.show('Training aktualisiert', {
            cssClass: 'alert-success', timeout: 4000
          });
          // Redirect to dash
          this.router.navigate([`/client/${this.actRoute.snapshot.params.id}/training-overview`]);
        }
      );
    });
  }

  cancel(comeFromDashboard: boolean): void {
    if (comeFromDashboard) {
      // Show message
      this.flashMessage.show('Kein neues Training angelegt', {
        cssClass: 'alert-danger', timeout: 4000
      });
      // Redirect to dash
      this.router.navigate([`/client/${this.actRoute.snapshot.params.id}/client-dashboard`]);
    } else {
      // Show message
      this.flashMessage.show('Änderungen wurden nicht gespeichert', {
        cssClass: 'alert-danger', timeout: 4000
      });
      // Redirect to dash
      this.router.navigate([`/client/${this.actRoute.snapshot.params.id}/training-overview`]);
    }

  }

  onReadOnChangeExDiff(exDiff: number): void {
    switch (exDiff) {
      case(0): {this.currExcsDiff = DifficultyLevel.beginner;
                this.currExcsDiffAsStr = 'Beginner'; break; }
      case(1): {this.currExcsDiff = DifficultyLevel.easy;
                this.currExcsDiffAsStr = 'Einfach'; break; }
      case(2): {this.currExcsDiff = DifficultyLevel.medium;
                this.currExcsDiffAsStr = 'Medium'; break; }
      case(3): {this.currExcsDiff = DifficultyLevel.hard;
                this.currExcsDiffAsStr = 'Schwer'; break; }
      case(4): {this.currExcsDiff = DifficultyLevel.extreme;
                this.currExcsDiffAsStr = 'Extrem'; break; }
    }
  }
}
