import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Trainer } from '../models/Trainer';
import { Client } from '../models/Client';


import { AuthService } from './auth.service';
import { Timestamp } from 'rxjs/internal/operators/timestamp';


@Injectable({
    providedIn: 'root'
  })

export class TrainerService {
    trainersCollection: AngularFirestoreCollection<Trainer>;
    trainerDoc: AngularFirestoreDocument<Trainer>;
    trainers: Observable<Trainer[]>;
    trainer: Observable<Trainer>;
    user_list: string[] = [];
    user: Observable<Client>;

    timeStamp: Timestamp<Date>;


    constructor(private afs: AngularFirestore,
                private authService: AuthService) {
      this.trainersCollection = this.afs.collection('trainers'); // rename it "trainers" after implementation in firestore
    }

     getTrainer(TrainerId: string): Observable<Trainer> {
      this.trainerDoc = this.afs.doc(`trainers/${TrainerId}`);
      this.trainer = this.trainerDoc.snapshotChanges().pipe(map(action => {
        if (action.payload.exists === false) {
          return null;
        } else {
          const data = action.payload.data() as Trainer;
          data.id = TrainerId;
          data.first_name = action.payload.get('first_name');
          data.last_name = action.payload.get('last_name');
          data.gender = action.payload.get('gender');
          data.birthday = action.payload.get('birthday');
          data.organisation = action.payload.get('organisation');
          data.sector = action.payload.get('sector');
          data.e_mail = action.payload.get('e_mail');
          data.phone = action.payload.get('phone');
          data.address = action.payload.get('address');
          data.user_list  = action.payload.get('user_list');
          // set property 'user_list' for current trainer session
          this.user_list = data.user_list;
          return data;
        }
      }));

      return this.trainer;
    }

    // UPDATE TRAINER METHOD
    updateTrainer(trainer: Trainer): Trainer {
      // TBD
      // some nice update document magic
      return trainer;
    }

    addUserToTrainersUsrList(newClientID: string): void {
      this.user_list.push(newClientID);
      this.trainerDoc.update({user_list: this.user_list});
    }

    removeUserFromTrainersUsrList(clientID: string): void {
      //this.user_list.pop()
      console.log(`before deleting: ${this.user_list}`);
      this.user_list = this.user_list.filter(item => !(item === clientID));
      console.log(`after deleting: ${this.user_list}`);
      this.trainerDoc.update({user_list: this.user_list});
    }

    // ADD TRAINER METHOD
  newTrainer(trainer: Trainer, docID: string): void {
      this.afs.collection('trainers').doc(docID).set(trainer);
  }

    newTrainer2(trainer: Trainer, docID: string): void {

      // this.timeStamp = Timestamp.fromDate(new Date("December 10, 1815"));

      // this.afs.collection('trainers').doc(`testDoc999`).set(this.trainer);
      this.afs.collection('trainers').doc(`UgcBLibScnVrzCIQ7ZoxxzFt8A23`).set(
        {first_name: 'Peter',
        last_name: 'Lustig',
        birthday: '',
        e_mail: 'p.lustig@life-kinetik.web',
        phone: '+49 800 1234567',
        gender: 'm',
        organisation: 'Lustig Life Kinetik & more GmbH',
        sector: 'Life Kinetik',
        address: {
          house_number: 18,
          location: 'Regensburg',
          street: 'Bahnhofstr.',
          zip_code: 93047},
          user_list: ['1NLS3E6ktUYhjfLwkyRc5plSfIm1',
                      'qtHS6ASWDnjjw0GZ5sNX']
        });

    }

}
