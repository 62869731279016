<div class="row">
    <div class="col-md-9 mx-auto">
        <div class="card">
            <div class="sul-box-raised-1 align-items-center d-flex flex-column p-2">
                <div class="my-3">
                  <img src="./assets/img/brainjo.png" height="150">
                </div>
                <div>
                    <h4 class="text-center pad">
                      Als Trainer registrieren
                    </h4>
                </div>
                <div>
                    <form #trainerForm="ngForm" (ngSubmit)="onSubmit(trainerForm)" class="my-3 mx-1">
                        <div class="mb-3">
                            <label for="e_mail" class="form-label mb-0">Email</label>
                            <input type="email"
                                   id="e_mail"
                                   name="e_mail"
                                   #trainerEmail="ngModel"
                                   [ngClass]="{'is-invalid':trainerEmail.errors && trainerEmail.touched}"
                                   [(ngModel)]="trainer.e_mail"
                                   class="form-control sul-text-field"
                                   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                                   required>
                          <div [hidden]="!trainerEmail.errors?.required" class="invalid-feedback">Email is required</div>
                          <div [hidden]="!trainerEmail.errors?.pattern" class="invalid-feedback">Email is not valid</div>
                        </div>
                        <div class="my-3">
                            <label for="firstName" class="form-label mb-0">Vorname</label>
                            <input type="text"
                                   id="firstName"
                                   name="firstName"
                                   #trainerFirstName="ngModel"
                                   [ngClass]="{'is-invalid':trainerFirstName.errors && trainerFirstName.touched}"
                                   [(ngModel)]="trainer.first_name"
                                   class="form-control sul-text-field"
                                   minlength="3"
                                   required>
                          <div [hidden]="!trainerFirstName.errors?.required" class="invalid-feedback">First Name is required</div>
                          <div [hidden]="!trainerFirstName.errors?.minlength" class="invalid-feedback">Must be at least 3 characters</div>
                        </div>
                        <div class="my-3">
                            <label for="lastName" class="form-label mb-0">Nachname</label>
                            <input type="text"
                                   id="lastName"
                                   name="lastName"
                                   #trainerLastName="ngModel"
                                   [ngClass]="{'is-invalid':trainerLastName.errors && trainerLastName.touched}"
                                   [(ngModel)]="trainer.last_name"
                                   class="form-control sul-text-field"
                                   minlength="3"
                                   required>
                          <div [hidden]="!trainerLastName.errors?.required" class="invalid-feedback">Last Name is required</div>
                          <div [hidden]="!trainerLastName.errors?.minlength" class="invalid-feedback">Must be at least 3 characters</div>
                        </div>
                        <div class="my-3">
                            <label for="password" class="form-label mb-0">Passwort</label>
                            <input type="text"
                                   id="password"
                                   name="password"
                                   #trainerPassword="ngModel"
                                   [ngClass]="{'is-invalid':trainerPassword.errors && trainerPassword.touched}"
                                   [(ngModel)]="password"
                                   class="form-control sul-text-field"
                                   minlength="8"
                                   required>
                          <div [hidden]="!trainerPassword.errors?.required" class="invalid-feedback">Password is required</div>
                          <div [hidden]="!trainerPassword.errors?.minlength" class="invalid-feedback">Password must be at least 8 characters</div>
                        </div>
                        <div class="form-group row my-3">
                            <label for="gender" class="col-sm-4 col-form-label pt-0">Geschlecht</label>
                            <div id="gender" class="col-sm-8 row">
                              <div id>
                                <input type="radio" class="sul-radio mb-3 me-2" name="gender" value = 'male' [(ngModel)]="trainer.gender"> männlich
                              </div>
                              <div>
                                <input type="radio" class="sul-radio mb-3 me-2" name="gender" value = 'female' [(ngModel)]="trainer.gender"> weiblich
                              </div>
                              <div>
                                <input type="radio" class="sul-radio me-2" name="gender" value = 'diverse' [(ngModel)]="trainer.gender"> divers
                              </div>
                            </div>
                        </div>
                        <div class="my-3">
                            <label for="birthday" class="form-label mb-0">Geburtstag</label>
                            <input type="date"
                                   id="birthday"
                                   name="birthday"
                                   #trainerBirthday="ngModel"
                                   [ngClass]="{'is-invalid':trainerBirthday.errors && trainerBirthday.touched}"
                                   [(ngModel)]="trainer.birthday"
                                   class="form-control sul-text-field"
                                   required>
                          <div [hidden]="!trainerBirthday.errors?.required" class="invalid-feedback">Birthday is required</div>
                        </div>
                      <fieldset ngModelGroup="address">
                        <div class="my-3">
                              <label for="street" class="form-label mb-0">Straße</label>
                              <input type="text"
                                     id="street"
                                     name="street"
                                     #trainerAddressStreet="ngModel"
                                     [ngClass]="{'is-invalid':trainerAddressStreet.errors && trainerAddressStreet.touched}"
                                     ngModel
                                     class="form-control sul-text-field"
                                     minlength="8"
                                     required>
                          <div [hidden]="!trainerAddressStreet.errors?.required" class="invalid-feedback">Street is required</div>
                          <div [hidden]="!trainerAddressStreet.errors?.minlength" class="invalid-feedback">Must be at least 8 characters</div>
                        </div>
                        <div class="my-3">
                          <label for="houseNumber" class="form-label mb-0">Hausnummer</label>
                          <input type="text"
                                 id="houseNumber"
                                 name="houseNumber"
                                 #trainerAddressHouseNumber="ngModel"
                                 [ngClass]="{'is-invalid':trainerAddressHouseNumber.errors && trainerAddressHouseNumber.touched}"
                                 ngModel
                                 class="form-control sul-text-field"
                                 required>
                          <div [hidden]="!trainerAddressHouseNumber.errors?.required" class="invalid-feedback">House number is required</div>
                        </div>
                        <div class="my-3">
                          <label for="location" class="form-label mb-0">Ort</label>
                          <input type="text"
                                 id="location"
                                 name="location"
                                 #trainerAddressLocation="ngModel"
                                 [ngClass]="{'is-invalid':trainerAddressLocation.errors && trainerAddressLocation.touched}"
                                 ngModel
                                 class="form-control sul-text-field"
                                 minlength="3"
                                 required>
                          <div [hidden]="!trainerAddressLocation.errors?.required" class="invalid-feedback">House number is required</div>
                          <div [hidden]="!trainerAddressLocation.errors?.minlength" class="invalid-feedback">Must be at least 3 characters</div>
                        </div>
                          <div class="my-3">
                            <label for="zipCode" class="form-label mb-0">PLZ</label>
                            <input type="text"
                                   id="zipCode"
                                   name="zipCode"
                                   #trainerAddressZipCode="ngModel"
                                   [ngClass]="{'is-invalid':trainerAddressZipCode.errors && trainerAddressZipCode.touched}"
                                   ngModel
                                   class="form-control sul-text-field"
                                   minlength="5"
                                   required>
                            <div [hidden]="!trainerAddressZipCode.errors?.required" class="invalid-feedback">Zip code is required</div>
                            <div [hidden]="!trainerAddressZipCode.errors?.minlength" class="invalid-feedback">Must be at least 5 characters</div>
                          </div>
                      </fieldset>
                        <div class="my-3">
                            <label for="phone" class="form-label mb-0">Telefon</label>
                            <input type="text"
                                   id="phone"
                                   name="phone"
                                   #trainerPhone="ngModel"
                                   [ngClass]="{'is-invalid':trainerPhone.errors && trainerPhone.touched}"
                                   [(ngModel)]="trainer.phone"
                                   class="form-control sul-text-field"
                                   minlength="8"
                                   required>
                          <div [hidden]="!trainerPhone.errors?.required" class="invalid-feedback">Phone is required</div>
                          <div [hidden]="!trainerPhone.errors?.minlength" class="invalid-feedback">Must be at least 8 characters</div>
                        </div>
                        <div class="my-3">
                            <label for="organisation" class="form-label mb-0">Organisation</label>
                            <input type="text"
                                   id="organisation"
                                   name="organisation"
                                   #organisation="ngModel"
                                   [ngClass]="{'is-invalid':organisation.errors && organisation.touched}"
                                   (ngModel)="trainer.organisation"
                                   class="form-control sul-text-field"
                                   minlength="3"
                                   required>
                          <div [hidden]="!organisation.errors?.required" class="invalid-feedback">Organisation name is required</div>
                        </div>
                        <div class="my-3">
                          <label for="sector" class="form-label mb-0">Sektor/Branche</label>
                          <input type="text"
                                 id="sector"
                                 name="sector"
                                 #sector="ngModel"
                                 [ngClass]="{'is-invalid':sector.errors && sector.touched}"
                                 (ngModel)="trainer.sector"
                                 class="form-control sul-text-field"
                                 minlength="3"
                                 required>
                          <div [hidden]="!sector.errors?.required" class="invalid-feedback">Sector category is required</div>
                        </div>
                        <div class="mx-auto my-4 align-items-center d-flex flex-column">
                          <input type="submit" value="Als Trainer Registrieren" class="sul-btn px-3 py-2 ">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


