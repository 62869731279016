import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { FlashMessagesService } from 'flash-messages-angular';
import { Router } from '@angular/router';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import {Client} from '../../models/Client';
import {Trainer} from '../../models/Trainer';
import {TrainerService} from '../../services/trainer.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  birthday: Date;
  gender: string;
  street: string;
  houseNumber: string;
  location: string;
  zipCode: number;
  phone: number;
  organisation: string;

  trainer = new Trainer();
  tempDate: Date = new Date();


  constructor(
    private authService: AuthService,
    private trainerService: TrainerService,
    private router: Router,
    private flashMessage: FlashMessagesService
  ) { }

  ngOnInit(): void {
  }

  onSubmit({value, valid}: { value: Trainer, valid: boolean }): void {
    if (!valid) {
      // Show error
      this.flashMessage.show('Please fill out the form correctly', {
        cssClass: 'alert-danger', timeout: 4000
      });
    } else {
      this.authService.register(value.e_mail, this.password)
        .then(traCreds => {

          // set new assigned trainer-ID
          value.id = traCreds.user.uid;

          // set empty user-list
          value.user_list = [];

          // Convert birthday JS-Date-object to Firestore Timestamp-object
          this.tempDate = new Date(`${this.tempDate}`);
          value.birthday = Timestamp.fromDate(this.tempDate);

          // Add new trainer document with assigned client-ID
          this.trainerService.newTrainer(value, value.id);

          this.flashMessage.show('You are now registered', {cssClass: 'alert-success', timeout: 4000});
          this.router.navigate(['/']);
        });
    }
  }

}
