<div class="pad-stats">
  <div class="row">
    <div class="col align-self-start stats-style">
      <p class="text-center fw-bold mb-0">{{this.first_number}}</p>
      <p class="text-center">{{this.first_label}}</p>
    </div>
    <div class="col align-self-center stats-style">
    </div>
    <div class="col align-self-end stats-style">
      <p class="text-center fw-bold mb-0">{{this.second_number}}</p>
      <p class="text-center">{{this.second_label}}</p>
    </div>
  </div>
</div>
