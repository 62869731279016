import { Component, Input, OnInit } from '@angular/core';

/* e.g.
  <double-statistic
    first_label="Mitglieder gesamt:"
    seconde_label="Derzeit aktiv:"
    first_number="50 #"
    seconde_number="12 #">
  </double-statistic>
  -----or with variables
  <double-statistic
    first_label="Trainings offen"
    seconde_label="Trainings abgeschlossen"
    [first_number]="countStagedtrainings"
    [seconde_number]="countSavedtrainings">
  </double-statistic>
*/
@Component({
  selector: 'double-statistic',
  templateUrl: './double-statistic.component.html',
  styleUrls: ['./double-statistic.component.scss']
})
export class DoubleStatisticComponent implements OnInit {
  @Input() first_label: string;
  @Input() second_label: string;
  @Input() first_number: number;
  @Input() second_number: number | string;

  constructor() {
   }

  ngOnInit(): void {
  }

}
