import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FlashMessagesModule } from 'flash-messages-angular';

import { environment } from '../environments/environment';
import { AngularFireModule} from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DatePipe } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';


import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HeadingComponent } from './components/heading/heading.component';
import { DoubleStatisticComponent } from './components/double-statistic/double-statistic.component';
import { TripleStatisticComponent } from './components/triple-statistic/triple-statistic.component';
import { TrainerDashboardComponent } from './pages/trainer-dashboard/trainer-dashboard.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { AddClientComponent } from './pages/add-client/add-client.component';
import { EditClientComponent } from './pages/edit-client/edit-client.component';
import { ClientDetailsComponent } from './pages/client-details/client-details.component';
import { LoginComponent } from './pages/login/login.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { RegisterComponent } from './pages/register/register.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AppRoutingModule } from './app-routing.module';

import { ClientService } from './services/client.service';
import { AuthService } from './services/auth.service';
import { SettingsService } from './services/settings.service';
import { ClientDashboardComponent } from './pages/client-dashboard/client-dashboard.component';
import { TrainingseditorComponent } from './pages/trainingseditor/trainingseditor.component';
import { TrainingOverviewComponent } from './pages/training-overview/training-overview.component';
import { NewExerciseDialogComponent } from './components/newExercise-dialog/new-exercise-dialog.component';
import { ExerciseComponent } from './components/exercise/exercise.component';
import { TrainingInsightsComponent } from './pages/training-insights/training-insights.component';



@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeadingComponent,
    DoubleStatisticComponent,
    TripleStatisticComponent,
    TrainerDashboardComponent,
    ClientsComponent,
    AddClientComponent,
    EditClientComponent,
    ClientDetailsComponent,
    LoginComponent,
    SettingsComponent,
    RegisterComponent,
    NotFoundComponent,
    ClientDashboardComponent,
    TrainingseditorComponent,
    TrainingOverviewComponent,
    NewExerciseDialogComponent,
    ExerciseComponent,
    TrainingInsightsComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    FlashMessagesModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase, 'clientpanel'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    NgApexchartsModule,
    FlashMessagesModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatToolbarModule,
    MatSnackBarModule,
    DragDropModule,
    ChartsModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [MatFormFieldModule],
  providers: [ClientService, AuthService, SettingsService, DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [NewExerciseDialogComponent]
})
export class AppModule { }
