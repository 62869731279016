import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'flash-messages-angular';
import { SettingsService } from '../../services/settings.service';
import { Settings } from '../../models/Settings';
import { popper } from '@popperjs/core';

import { Client } from '../../models/Client';
import { TrainerService } from 'src/app/services/trainer.service';
import { Trainer } from 'src/app/models/Trainer';
import { AuthService } from 'src/app/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Popper from 'popper.js';





@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})

@Injectable({
  providedIn: 'any'
})


export class SettingsComponent implements OnInit {
  settings: Settings;
  unsubscribe: Subject<void> = new Subject();
  trainer = new Trainer();
  users: Client[];

  constructor(
    private authService: AuthService,
    private router: Router,
    private flashMessage: FlashMessagesService,
    private settingsService: SettingsService,
    private trainerService: TrainerService
  ) { }

  ngOnInit(): void {
    this.settings = this.settingsService.getSettings();
    this.authService.getAuth().pipe(takeUntil(this.unsubscribe)).subscribe(auth => {
      if (auth) {
        this.trainerService.getTrainer(auth.uid).pipe(takeUntil(this.unsubscribe)).subscribe(trainer => {
          // this.users = [];
          this.trainer = trainer;
          });
        }

      // console.log('Add  new trainer to database:');
      // this.trainerService.newTrainer(this.trainer);

    });
  }

  onSubmit(): void {
    this.settingsService.changeSettings(this.settings);
    this.flashMessage.show('Settings saved', {cssClass: 'alert-success', timeout: 4000});
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
