import {Component, OnDestroy, OnInit} from '@angular/core';
import {Client} from '../../models/Client';
import {takeUntil} from 'rxjs/operators';
import {ClientService} from '../../services/client.service';
import {TrainerService} from '../../services/trainer.service';
import {AuthService} from '../../services/auth.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {Trainer} from '../../models/Trainer';
import {DatePipe} from '@angular/common';
import {Training} from '../../models/Training';
import {FlashMessagesService} from 'flash-messages-angular';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-training-insights',
  templateUrl: './training-overview.component.html',
  styleUrls: ['./training-overview.component.scss']
})
export class TrainingOverviewComponent implements OnInit, OnDestroy {
  trainer = new Trainer();
  users: Client[];

  client = new Client();
  clientId: string;
  currTrainingID: string;
  routeToEditorBlocked = false;

  clientLoaded: Promise<boolean>;

  unsubscribe: Subject<void> = new Subject();

  constructor(public clientService: ClientService,
              private trainerService: TrainerService,
              private authService: AuthService,
              private afs: AngularFirestore,
              public router: Router,
              private flashMessage: FlashMessagesService,
              private actRoute: ActivatedRoute,
              public datepipe: DatePipe,
              private modalService: NgbModal,
              config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.authService.getAuth().pipe(takeUntil(this.unsubscribe)).subscribe(auth => {
      if (auth) {
        this.trainerService.getTrainer(auth.uid).pipe(takeUntil(this.unsubscribe)).subscribe(trainer => {
          this.trainer = trainer;
          this.users = this.clientService.getClients(this.trainer.user_list);

          // get clientID from URL
          this.clientId =  this.actRoute.snapshot.params.id;

          // set client document
          const clientDoc = this.afs.doc<Client>(`users/${this.clientId}`);

          // get client specific dataset
          this.clientService.getClientDataset(clientDoc).subscribe(client => {
              this.clientService.queryTrainings(clientDoc, 'staged', false).subscribe(trs => {
                client.staged_trainings = trs; client.staged_trainings.sort((a, b) => (a.deadline > b.deadline) ? 1 : -1); });
              this.clientService.queryTrainings(clientDoc, 'saved', true).subscribe(trs => {
                client.saved_trainings = trs; client.saved_trainings.sort((a, b) => (a.completed_at > b.completed_at) ? 1 : -1); });

              this.client = client;

              // Setting the Promise as resolved after we have the needed data
              this.clientLoaded = Promise.resolve(true);
            },
            err => {},
            () => {});
        });
      }

    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.clientService.unsubscribe.next();
    this.clientService.unsubscribe.complete();

  }

  navigateToEditor(training: Training): void {
    const stagedTrainingsCounter = this.client.staged_trainings.length;
    const savedTrainingsCounter = this.client.saved_trainings.length;
    this.router.navigate([`client/${this.clientId}/trainings-editor`],
      {state: {training, stagedTrainingsCounter, savedTrainingsCounter}});
  }

  blockNavToEditor(): void {
    this.routeToEditorBlocked = true;
    setTimeout( () => {this.routeToEditorBlocked = false; }, 1000 );
  }

  navigateToInsights(trainingID: string): void {
    const savedTrainingsCounter = this.client.saved_trainings.length;
    this.router.navigate([`client/${this.clientId}/training-insights/${trainingID}`],
    {state: {trainingID}});
  }

  rememberTrainingID(trainingID): void {
    this.currTrainingID = trainingID;
  }
}
