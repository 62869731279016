<div class="row">
    <div class="col-md-6">
        <a routerLink="/" class="btn btn-link">
            <i class="fa fa-arrow-circle-o-left"></i>Back to Dashbard
        </a>
    </div>
    <div class="col-md-6">
        <div class="btn-group pull-right">
            <a routerLink="/client/edit/{{ id }}" class="btn btn-dark">
                Edit
            </a>
            <button (click)="onDeleteClick()" class="btn btn-danger">Delete</button>
        </div>
    </div>
</div>
<hr>
<div *ngIf="client" class="card">
    <h3 class="card-header">{{ client.firstName }} {{ client.lastName }}</h3>
    <div class="card-body">
        <div class="row">
            <div class="cold-md-8 col-sm-6">
                <h4>CLient ID: {{ client.id }}</h4>
            </div>
            <div class="cold-md-8 col-sm-6">
                <h3 class="pull-right">
                    Balance
                    <span
                        [class.text-danger]="hasBalance"
                        [class.text-success]="!hasBalance">
                        {{ client.balance | currency:"USD":"symbol" }}
                    </span>
                    <small>
                        <a (click)="showBalanceUpdateInput = !showBalanceUpdateInput">
                            <i class="fa fa-pencil"></i>
                        </a>
                    </small>
                </h3>
                <div class="clearfix">
                    <form *ngIf="showBalanceUpdateInput" (submit)="updateBalance(id)">
                        <div class="input-group">
                            <input type="text" name="balance" class="form-control" [(ngModel)]="client.balance">
                            <span class="input-group-btn">
                                <button type="submit" class="btn btn-primay btn-dark">Update</button>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <hr>
        <ul class="list-group">
            <li class="list-group-item">Contact Email: {{ client.email }}</li>
            <li class="list-group-item">Contact Phone: {{ client.phone }}</li>
        </ul>
    </div>
</div>
