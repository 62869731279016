<div class="container">

  <div class="row">
    <div class="col align-self-center">
      <div class="my-5">
        <h4 class="text-center pad"><b>Willkommen zurück!</b></h4>
        <p class="lead text-center">{{ this.quote }}</p>
        <!--<p class="lead"><b>- Mike Singletary</b><p>-->
      </div>
      <div class="d-flex justify-content-center my-5">
        <button routerLink="/clients" type="submit" value="Training speichern" class="sul-btn p-2 text-center">Meine Clients</button>
      </div>
    </div>
  </div>


  <double-statistic
    first_label="Trainings offen"
    second_label="Trainings abgeschlossen"
    [first_number]="countStagedtrainings"
    [second_number]="countSavedtrainings">
  </double-statistic>
</div>
