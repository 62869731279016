import {Component, OnDestroy, OnInit} from '@angular/core';
import { ClientService } from '../../services/client.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FlashMessagesService } from 'flash-messages-angular';
import { Client } from '../../models/Client';
import { DatePipe } from '@angular/common';
import {Subject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;


@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit, OnDestroy {
  clientId: string;
  client = new Client();
  handicaps: string[] = [];
  handicapValue = '';
  tempDate: Date = new Date();
  prettyDateString: string;

  clientLoaded: Promise<boolean>;

  unsubscribe: Subject<void> = new Subject();

constructor(
    public clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private flashMessage: FlashMessagesService,
    public datepipe: DatePipe,
    private afs: AngularFirestore,
  ) {}

  ngOnInit(): void {
    // get clientID from URL
    this.clientId = this.route.snapshot.params.id;

    // set client document
    const clientDoc = this.afs.doc<Client>(`users/${this.clientId}`);

    // get client specific dataset
    this.clientService.getClientMetaData(clientDoc).pipe(takeUntil(this.unsubscribe)).subscribe((client => {
      this.client = client;

      this.handicaps = this.client.health_data.handicaps;
      this.prettyDateString = this.datepipe.transform(client.birthday.toDate(), 'dd.MM.yyyy');

      // Setting the Promise as resolved after we have the needed data
      this.clientLoaded = Promise.resolve(true);
    }

    ));
  }

  onSubmit({value, valid}: {value: Client, valid: boolean}): void {
    if (!valid) {
      this.flashMessage.show('Please fill out the form correctly', {cssClass: 'alert-danger', timeout: 4000});
    } else {
      // Add id to client
      value.id = this.clientId;
      // set clients handicaps field from temporary component handicap list
      value.health_data.handicaps = this.handicaps;
      // Convert birthday JS-Date-object to Firestore Timestamp-object
      this.tempDate = new Date(`${this.tempDate}`);
      value.birthday = Timestamp.fromDate(this.tempDate);
      // Update client
      this.clientService.updateClient(value);
      this.flashMessage.show('Client updated', {cssClass: 'alert-success', timeout: 4000});
      this.router.navigate([`/client/${this.clientId}/client-dashboard`]);
    }
  }

  add_handicap(event: Event): void {
    if ((event.target as HTMLInputElement).value !== ''){
      this.handicaps.push((event.target as HTMLInputElement).value);
    }
    this.handicapValue = '';
  }

  add_handicap_button(): void{
    if (this.handicapValue !== ''){
      this.handicaps.push(this.handicapValue);
    }
    this.handicapValue = '';
  }

  remove_handicap(val: string): void {
    this.handicaps.splice(this.handicaps.indexOf(val, 0), 1);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();

    this.clientService.unsubscribe.next();
    this.clientService.unsubscribe.complete();
  }

}
